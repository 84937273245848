import React, { useState, useEffect } from 'react'
import {
  CustomerNotification, CustomerPurchaseNotification,
  CustomerRequestNotification, BusinessNotification, BusinessPaymentNotification, BusinessRequestNotification
} from './CustomerNotification'
import SmartAccordion from '../../../components/site/FormElements/SmartAccordion'
import { useSiteContext } from "../../../contexts/SiteProvider";
import { SmartSoftCheckRadioSwitch } from "soft_digi";
import { showAlertAutoClose } from '../../../services/notifyService';
import { post, get } from '../../../services/smartApiService';
import { ADMIN_SETTINGS } from '../../../api/Admin/OperationsUrl';
import { USER_URLS } from "../../../api/Admin/AurhUrls";

const NotificationConfig = () => {
  const { setLoading, closeModal } = useSiteContext();
  const [category, setCategory] = useState("1");
  const [data, setTabData] = useState([]);
  const [formData, setFormData] = useState({});
  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };


  const loadTableData = () => {
    //setLoading(true, "Logging in Please Wait....");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    let post_data = { category: category };
    let url = USER_URLS.GET_ALL_ADMIN;
    const subscription = post(url, post_data, handleError).subscribe(
      (response) => {
        setTabData(response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, [category]);




  const handleSubmit = () => {
    //setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Details Submitting Please Wait....");
    let url = ADMIN_SETTINGS.INSERT;
    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        showAlertAutoClose("Data Saved Successfully", "success");
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const loadSettingsData = () => {
    setLoading(true, "Logging in Please Wait....");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    const subscription = get(
      ADMIN_SETTINGS.GET_ALL,
      handleError
    ).subscribe((response) => {
      setFormData(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadSettingsData();
  }, []);

  // 
  const getFormElements = (settings) => {
    let formElements = [];
    for (let i = 0; i < settings.length; i++) {
      formElements.push({
        type: "TEXTAREA",
        width: "6",
        name: settings[i].name,
        element: {
          label: settings[i].title,       
          inputType: "BORDER_LABEL",
          inputProps: { isFocussed: true },
          rows:3
        },
      })
    }
    return formElements;
  }

  const MyOfferSwitch = () => {
    const Customer_Data = [{ value: "1", label: "Customer Transaction" }];
    const Business_Data = [{ value: "2", label: "Business Transaction" }];

    return (
      <>
        <div className="is-flex">
          <div className="my-offer-month-switch mr-5">
            <SmartSoftCheckRadioSwitch
              options={Customer_Data}
              switchMode={true}
              isRight={true}
              name="switchone"
              value={category}
              onChange={(value) => setCategory("1")}
            />
          </div>
          <div className="my-offer-year-switch">
            <SmartSoftCheckRadioSwitch
              options={Business_Data}
              switchMode={true}
              isRight={true}
              name="switch"
              value={category}
              onChange={(value) => setCategory("2")}
            />
          </div>
        </div>
      </>
    );
  };
  const data_one = [
    {
      title: "Account Notification",
      body: <CustomerNotification getFormElements={getFormElements}
        formData={formData} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
      // body:<BusinessNotification />
    },
    {
      title: "Purchase and Redemption Notification",
      body: <CustomerPurchaseNotification getFormElements={getFormElements}
        formData={formData} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
    },
    {
      title: "Request and Complaint Notification",
      body: <CustomerRequestNotification getFormElements={getFormElements}
        formData={formData} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
    },

  ]

  const business_notification = [
    {
      title: "Account Notification",
      body: <BusinessNotification getFormElements={getFormElements}
        formData={formData} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
    },
    {
      title: "Invoice and Payment Notifications",
      body: <BusinessPaymentNotification getFormElements={getFormElements}
        formData={formData} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
    },
    {
      title: "Request and Complaint Notifications",
      body: <BusinessRequestNotification getFormElements={getFormElements}
        formData={formData} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
    },
  ]

  return (
    <div>
      {MyOfferSwitch()}
      {category === "1" && <SmartAccordion key="customer" data={data_one}
        class_name="crop-admin-accord-card mb-5" />}
      {category === "2" && <SmartAccordion key="business" data={business_notification}
        class_name="crop-admin-accord-card mb-5" />
      }
    </div>
  )
}

export default NotificationConfig