import React, { useEffect, useState } from "react";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import {
  SmartSoftButton,
  SmartSoftCheckRadioSwitch,
  SmartSoftInput,
  SmartSoftSelect,
  SmartValid,
  SmartSoftForm,
  SmartSOftTextArea,
} from "soft_digi";
import SmartSelect from "../../../../components/core/forms/SmartSelect";
import { COMPLAINT_FORM } from "../../../../services/ImageService";
import { SERVICES_COMPLAINT_URLS } from "../../../../api/Services/ServiceUrls";
import {
  showAlertAutoClose,
  showNotification,
} from "../../../../services/notifyService";
import { post } from "../../../../services/smartApiService";

const ComplaintForm = ({ loadTableData, dataIn }) => {
  const [formData, setFormData] = useState(dataIn ? dataIn : {});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading, closeModal } = useSiteContext();
  const [roles, setRoles] = useState([]);
  //const [type, setType] = useState("password");

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };
  const handleSubmit = () => {
    setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Details Submitting Please Wait....");
    let url = SERVICES_COMPLAINT_URLS.INSERT;
    formData.complaint_status = formData.complaint_status.value;
    if (formData.ID !== undefined) {
      formData["id"] = formData.ID;
      url = SERVICES_COMPLAINT_URLS.UPDATE;
    }

    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        //console.log("response form ", response.data);
        loadTableData();
        showAlertAutoClose("Data Saved Successfully", "success");
        closeModal();
        // setUser(response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {}, []);

  const options = [
    { value: "OPEN", label: "OPEN" },
    { value: "PROGRESS", label: "PROGRESS" },
    { value: "CLOSED", label: "CLOSED" },
  ];
  const popupFormValidations = {
    Response: [SmartValid.required("Response is Required")],
    Status: [SmartValid.required("Status is Required")],
  };

  return (
    <>
      <div key={"ComplaintFormcolumnsone"} className="columns">
        <div
          key={"ComplaintFormcolumnone"}
          className=" column is-10 card crop-admin-modal-content"
        >
          <div key={"ComplaintFormcolumnstwo"} className="columns is-multiline">
            <div className="column is-12 has-text-centered" key="1">
              <p className="crop-admin-form-title">
                {" "}
                {dataIn && dataIn.ID !== undefined ? "" : "Update"}{" "}
              </p>
            </div>
            <div
              className="column is-12 is-flex is-multiline crop-admin-form"
              key="2"
            >
              <div key={"ComplaintFormcolumnone"} className="column is-6">
                <SmartSoftInput
                  label="Complaint Number"
                  value={formData?.complaint_id || ""}
                  inputType="BORDER_LABEL"
                  onChange={(value) => handleInputChange("complaint_id", value)}
                />
              </div>

              <div key={"ComplaintFormcolumntwo"} className="column is-6">
                <SmartSelect
                  key="text-1"
                  placeHolder="Status"
                  options={options}
                  value={formData?.complaint_status || ""}
                  onChange={(value) =>
                    handleInputChange("complaint_status", value)
                  }
                  errorEnable={true}
                  errorEnable={formSubmit}
                  errorUpdate={(value) => handleErrorChange("euserid", value)}
                  validations={popupFormValidations.Status}
                />
              </div>
            </div>

            <div className="column is-12  crop-admin-form" key="3">
              {/* <SmartSoftInput
                label="Response"
                value={formData?.complaint_resolution || ""}
                inputType="BORDER_LABEL"
                onChange={(value) =>
                  handleInputChange("complaint_resolution", value)
                }
                errorEnable={formSubmit}
                errorUpdate={(value) => handleErrorChange("euserid", value)}
                validations={popupFormValidations.Response}
              /> */}
              <SmartSOftTextArea
                key="text-7"
                label="Response"
                value={formData?.complaint_resolution || ""}
                onChange={(value) =>
                  handleInputChange("complaint_resolution", value)
                }
                placeHolder="Response"
                inputProps={{ isFocussed: true }}
                errorEnable={formSubmit}
                errorUpdate={(value) => handleErrorChange("euserid", value)}
                validations={popupFormValidations.Response}
              />
            </div>

            <div
              key={"ComplaintFormcolumnthree"}
              className="column is-6 is-offset-3 has-text-centered"
            >
              <SmartSoftButton
                key="save"
                label={dataIn && dataIn.ID !== undefined ? "UPDATE" : "UPDATE"}
                // leftIcon="fa-save"
                classList={["is-rounded crop-admin-action-button"]}
                onClick={handleSubmit}
              />
            </div>
            <div
              key={"ComplaintFormcolumnfour"}
              className="column is-12 has-text-centered"
            >
              <img src={COMPLAINT_FORM} alt="" />
            </div>
          </div>
        </div>
        <div key={"ComplaintFormcolumnfive"} className="column is-2">
          <div className="crop-admin-modal-close" onClick={closeModal}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComplaintForm;
