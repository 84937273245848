import {
  SmartSOftTextArea,
  SmartSoftButton,
  SmartSoftInput,
  SmartValid,
} from "soft_digi";
import React, { useEffect, useState } from "react";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import SmartSelect from "../../../../components/core/forms/SmartSelect";
import { COMPLAINT_FORM } from "../../../../services/ImageService";
const UpdatePopup = () => {
  // const [formData, setFormData] = useState(dataIn ? dataIn : {});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading, closeModal } = useSiteContext();

  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };
  const options = [
    { value: "OPEN", label: "OPEN" },
    { value: "PROGRESS", label: "PROGRESS" },
    { value: "CLOSED", label: "CLOSED" },
  ];
  const UpdatepopupFormValidations = {
    status: [SmartValid.required("Status is Required")],
    Description: [SmartValid.required("Description is Required")],
  };
  return (
    <>
      <div key={"UpdatePopupcolumnsone"} className="columns">
        <div
          key={"UpdatePopupcolumnone"}
          className=" column is-10 card crop-admin-modal-content "
        >
          <div key={"UpdatePopupcolumnstwo"} className="columns is-multiline">
            <div className="column is-12 has-text-centered" key="1">
              <p className="crop-admin-form-title">
                {/* {dataIn && dataIn.ID !== undefined ? "" : "Update"}{" "} */}
              </p>
            </div>
            <div
              className="column is-12 is-flex is-multiline crop-admin-form"
              key="2"
            >
              <div
                key={"UpdatePopupcolumntwo"}
                className="column is-12 account-settings-form"
              >
                <SmartSelect
                  key="text-1"
                  placeHolder="Status"
                  options={options}
                  // value={formData?.complaint_status || ""}
                  // onChange={(value) =>
                  //   handleInputChange("complaint_status", value)
                  // }
                  errorEnable={true}
                  // errorEnable={formSubmit}
                  errorUpdate={(value) => handleErrorChange("euserid", value)}
                  validations={UpdatepopupFormValidations.status}
                />
              </div>
              {/* <div className="column is-6 account-settings-form">
                <SmartSelect
                  key="text-1"
                  placeHolder="Status"
                  options={options}
                  // value={formData?.complaint_status || ""}
                  // onChange={(value) =>
                  //   handleInputChange("complaint_status", value)
                  // }
                  errorEnable={true}
                />
              </div> */}
            </div>

            <div className="column is-12  crop-admin-form" key="3">
              <SmartSOftTextArea
                label="Description"
                // value={formData?.complaint_description || ""}
                // inputType="BORDER_LABEL"
                // onChange={(value) =>
                //   handleInputChange("complaint_description", value)
                // }
                max={5000}
                errorEnable={formSubmit}
                errorUpdate={(value) => handleErrorChange("euserid", value)}
                validations={UpdatepopupFormValidations.Description}
              />
            </div>

            <div
              key={"UpdatePopupcolumnfour"}
              className="column is-6 is-offset-3 has-text-centered"
            >
              <SmartSoftButton
                key="save"
                label="UPDATE"
                // leftIcon="fa-save"
                classList={["is-rounded crop-admin-action-button"]}
                // onClick={handleSubmit}
              />
            </div>
            <div
              key={"UpdatePopupcolumnfive"}
              className="column is-12 has-text-centered"
            >
              <img src={COMPLAINT_FORM} alt="" />
            </div>
          </div>
        </div>
        <div key={"UpdatePopupcolumnsix"} className="column is-2">
          <div className="crop-admin-modal-close" onClick={closeModal}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatePopup;
