import {
  BUSSINESS_HEAD_TOP_RIGHT_lOGO,
  CROP_ADMIN_LAYOUT_MOON,
  PROFILE_PIC,
} from "../../services/ImageService";
import { useLocation } from "react-router-dom";
import SmartAccordion from "./../../components/site/FormElements/SmartAccordion";
import IMAGES from "./../../config/SiteImages";
import { SmartSoftButton } from "soft_digi";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const AdminHeader = ({ user, handleLogout, setPage,toggleSidebar }) => {
  const location = useLocation();
  const { pathname } = location;
  const getTitle = () => {
    if (pathname.includes("/operations")) {
      return "Operations Management";
    } else if (pathname.includes("/dashboard")) {
      return "Dashboard";
    } else if (pathname.includes("/account-settings")) {
      return "Account Security";
    } else if (pathname.includes("/notification")) {
      return "Notifications";
    } else if (pathname.includes("/account-management")) {
      return "Account Management";
    } else if (pathname.includes("/database")) {
      return "Database Management";
    } else {
      return "";
    }
  };
  //account-management
  // admin/database  account-settings

  const NotificationButton = () => {
    const viewprofile = () => {
      const viewprofiledata = [
        {
          title: "Congratulation Flora!",
          titletwo: "Won the monthly Best Seiler badge",
        },
        {
          title: "New user registered",
          titletwo: "5 hours ago",
        },
        {
          title: "New Request/Complaint received",
          titletwo: "you have 10 unread messages",
        },
        {
          title: "Stripe",
          titletwo: "Received Payment",
        },
      ];
      return (
        <>
          <div className="columns ">
            <div className="column ">
              <div className="card ">
                {viewprofiledata.map((item) => (
                  <div className="nknnknk has-text-left is-flex">
                    <span className="has-text-left ">
                      <img
                        className="NotificationButton-viewprofile-image has-text-left m-2"
                        src={PROFILE_PIC}
                        alt=""
                      />
                    </span>
                    <span className="has-text-weight-medium mt-2 has-text-left">
                      <p className="is-size-6">{item.title}</p>

                      <p className="is-size-7">{item.titletwo}</p>
                    </span>
                  </div>
                ))}
                ;
              </div>
            </div>
          </div>
        </>
      );
    };

    return (
      <>
        <div className="column  crop-AdminHeader-alarm-icon dropdown is-clickable is-hoverable is-right">
          <i class="fa fa-bell" aria-hidden="true"></i>
          <div className="dropdown-menu">
            <div className="dropdown-content pt-0">
              <div className="is-flex is-justify-content-space-between  AdminHeader-NotificationButton-header-content p-0">
                <p className="dropdown-item AdminHeader-NotificationButton-notification pl-5 pt-0 has-text-weight-medium is-size-4 ">
                  Notification
                </p>

                <button className="AdminHeader-NotificationButton-right-button  is-rounded pl-4 pr-4  mr-3 mt-2 mb-2">
                  8 New
                </button>
              </div>
              <div className="bottom-border"></div>
              <div className="dropdown-item p-0">{viewprofile()}</div>
              <div className="has-text-centered">
                <SmartSoftButton
                  label="Read All Notification"
                  classList={[
                    "smart-crop-admin-action-button",
                    "px-6 ",
                    "mt-3",
                    "mb-5",
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const HeaderProfile = () => {
    return (
      <>
        <div className="column p-0 dropdown is-clickable is-hoverable is-right">
          <figure class="image is-48x48 ">
            <img
              class="is-rounded"
              src={BUSSINESS_HEAD_TOP_RIGHT_lOGO}
              alt=""
            />
          </figure>
          {/*         
          <img
            src={BUSSINESS_HEAD_TOP_RIGHT_lOGO}
            alt="Business Head Top Right Logo"
          /> */}
          <div className=" dropdown-menu profile-drop-down">
            <header class="AdminHeader-NotificationButton-header-content">
              <p class=" has-text-centered has-text-weight-medium is-size-4 ">
                Profile
              </p>
            </header>
            <div className=" dropdown-content pb-3">
              <div className="dropdown-item">
                <div className="">
                  {/* <div className="column is-2 mt-2">
                    <figure class="image is-48x48">
                      <img
                        class="is-rounded"
                        src={BUSSINESS_HEAD_TOP_RIGHT_lOGO}
                      />
                    </figure>
                 
                  </div> */}

                  {/* <div className="column is-12 has-text-centered">
                    <img class="is-rounded" src={PROFILE_PIC} alt="" />
                    <div className="has-text-weight-bold ">
                      Hi {user?.ename}
                    </div>
                    <div>Goog Evening</div>
                    <div className="mt-2">
                      <b>Crop :</b>
                      {user?.crop_balance}
                    </div>
                  </div> */}

                  <div className="columns">
                    <div className="column is-flex">
                      <span className="has-text-left ">
                        <img
                          className="NotificationButton-viewprofile-image has-text-left ml-3 mt-3"
                          src={PROFILE_PIC}
                          alt=""
                        />
                      </span>
                      <span className="has-text-weight-medium mt-2 has-text-left">
                        <p className="is-size-6 ml-3">Pradeep Swains</p>

                        <p className="is-size-7 ml-3">Admin</p>
                      </span>
                    </div>
                  </div>
                </div>
                <Link to="/account-settings/account">
                  <p
                    className="smart-crop-action-button my-4 p-2 has-text-centered"
                    //  onClick={() => navigateLink("home/my-profile-form")}
                  >
                    <i class="fa fa-user-circle-o mr-1" aria-hidden="true"></i>
                    Profile
                  </p>
                </Link>
                
                {/* <Link to=""> */}
                <div
                  className="smart-crop-action-button my-4 p-2 has-text-centered"
                  onClick={handleLogout}
                >
                  <i class="fa fa-sign-out mr-1" aria-hidden="true"></i>
                  Logout
                </div>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="columns smart-head-nav-crop is-vcentered">
      <div className="column is-9 is-flex">
          <div onClick={()=>toggleSidebar()} className="smart-header-icon-admin mt-3">
          <i class="fa fa-bars" aria-hidden="true"></i>
          </div>
        <span className="crop-admin-header  ml-5">{getTitle()}</span>
      </div>
      <div className="column is-3 has-text-right pr-6 is-flex is-justify-content-space-between is-align-content-center">
        <span className="crop-admin-alarm-icon"></span>
        {/* <span className="crop-admin-alarm-icon">
          <img src={CROP_ADMIN_LAYOUT_MOON} />
        </span> */}
        <span className="crop-admin-alarm-icon">{NotificationButton()}</span>

        <span className="crop-admin-profile-image">{HeaderProfile()}</span>
      </div>
    </div>
  );
};

export default AdminHeader;
