import React, { useState, useEffect } from "react";
import { SmartSoftCheckRadioSwitch, SmartSoftTable } from "soft_digi";
import { useNavigate, useParams } from "react-router-dom";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { decrypt_data } from "../../../services/sessionService";
import { DATABASE_URLS, USER_URLS } from "../../../api/Admin/AurhUrls";
import { post } from "../../../services/smartApiService";
import { showAlertAutoClose } from "../../../services/notifyService";

const InvoiceTable = () => {
  const { id } = useParams();
  const decodeId = decodeURIComponent(id);
  const decrypted_id = decrypt_data(decodeId);
  const [category, setCategory] = useState("2");
  const [tabData, setTabData] = useState([]);
  const { setLoading, openModal, closeModal } = useSiteContext();
  const navigate = useNavigate();

  const baseNav = "/database";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const loadTableData = () => {
    //setLoading(true, "Logging in Please Wait....");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    let post_data = {
      id: decrypted_id,
      type: category,
    };
    let url = DATABASE_URLS.GET_ALL_INVOICE;
    const subscription = post(url, post_data, handleError).subscribe(
      (response) => {
        console.log(response.data);
        setTabData(response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, [category]);
  const viweline_button = [
    {
      label: " ",
      type: "icon",
      classList: ["smart-edit-icon"],
      leftIcon: "fa fa-file-text ",
    },
  ];
  const downloadline_button = [
    {
      label: " ",
      type: "icon",
      leftIcon: "fa fa-file-text ",
      classList: ["smart-delete-icon"],
    },
  ];

  const columns = [
    { title: "DATE", index: "order_date" },
    //{ title: "OFFER", index: "offerline" },
    // { title: "PRICE", index: "priceline" },
    //{ title: "QUANTITY", index: "quantityline" },
    { title: "Order Number", index: "order_number" },
    { title: "Invoice Number", index: "invoice_no" },
    { title: "TOTAL", index: "order_total" },
    { title: "CROPs", index: "order_earn_crop" },
    {
      title: "VIEW",
      index: "viewline",
      type: "buttons",
      buttons: viweline_button,
    },
    {
      title: "DOWNLOAD",
      index: "downloadline",
      type: "buttons",
      buttons: downloadline_button,
    },
    { title: "ORDER NUMBER", index: "order_no" },
    { title: "INVOICE NUMBER", index: "invoceline" },
  ];

  const MyOfferSwitch = () => {
    const [formData, setFormData] = useState({});
    const [formSubmit, setFormSubmit] = useState(false);
    const [formErrors, setFormErrors] = useState(false);
    const Customer_Details = [{ value: "1", label: "Customer Order Details" }];
    const Customer_Purchase_Details = [
      { value: "2", label: "Customer CROPs Details" },
    ];

    const handleInputChange = (name, value) => {
      setFormData((prev) => ({ ...prev, [name]: value }));
    };
    return (
      <>
        <div className="is-flex">
          <div className="my-offer-month-switch mr-5">
            <SmartSoftCheckRadioSwitch
              options={Customer_Details}
              switchMode={true}
              isRight={true}
              name="switchone"
              value={category}
              onChange={(value) => setCategory("1")}
            />
          </div>
          <div className="my-offer-year-switch">
            <SmartSoftCheckRadioSwitch
              options={Customer_Purchase_Details}
              switchMode={true}
              isRight={true}
              name="switch"
              value={category}
              onChange={(value) => setCategory("2")}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <div className="smart-crop-admin-container">
        <div key={"InvoiceTablecolumnsone"} className="columns">
          <div key={"InvoiceTablecolumnone"} className="column">
            <div key={"InvoiceTablecardone"} className="card">
              <div
                key={"InvoiceTablecolumnstwo"}
                className="columns is-vcentered"
              >
                <div
                  key={"InvoiceTablecolumntwo"}
                  className="column is-1 crop-admin-table-back"
                >
                  <span
                    className="icon"
                    onClick={() => navigateLink("database_management-list")}
                  >
                    <i className="fa fa-undo" aria-hidden="true"></i>
                  </span>
                </div>
                <div
                  key={"InvoiceTablecolumnthree"}
                  className="column is-8 crop-admin-table-title"
                >
                  {MyOfferSwitch()}
                </div>

                <div
                  key={"InvoiceTablecolumnfour"}
                  className="column is-3 has-text-right crop-table-top-pagination"
                >
                  {/* <i className="fa fa-caret-left" aria-hidden="true"></i>{" "}
                  <i className="fa fa-caret-right" aria-hidden="true"></i> */}
                </div>
              </div>
              <SmartSoftTable
                columns={columns}
                data={tabData}
                tableProps={{ className: "crop-table-layout" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceTable;
