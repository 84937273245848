import React from "react";
import { useNavigate } from "react-router-dom";

const OperationsManagementCards = () => {
  const navigate = useNavigate();
  const baseNav = "/operations";
  const handleNavigate = (index) => {
    navigate(baseNav + "/" + index);
  };

  const Operatio_cards = [
    {
      icons: <i class="fa fa-wrench" aria-hidden="true"></i>,
      title: "Services",
      link: "management-services-list",
    },
    /*
    {
      icons: <i class="fa fa-usd" aria-hidden="true"></i>,
      title: "Request",
      link: "management-request-list",
    },*/
    {
      icons: <i class="fa fa-user" aria-hidden="true"></i>,
      title: "Request & Complaint",
      link: "operations-compaint-list",
    },
    {
      icons: <i class="fa fa-building" aria-hidden="true"></i>,
      title: "Sector",
      link: "sector-list",
    },
    // {
    //   icons: <i class="fa fa-repeat" aria-hidden="true"></i>,
    //   title: "CROPs PROPs Management",
    //   link: "crop-management",
    // },
    // {
    //   icons: <i class="fa fa-industry" aria-hidden="true"></i>,
    //   title: "Tier Management",
    //   link:"tier-list"
    // },
    {
      icons: <i class="fa fa-usd" aria-hidden="true"></i>,
      title: "Payment Process",
      link: "payment-list",
    },
    // {
    //   icons: <i class="fa fa-star" aria-hidden="true"></i>,
    //   title: "Offer & Promo Management",
    //   link: "earn-crops-list",
    // },
    {
      icons: <i class="fa fa-repeat" aria-hidden="true"></i>,
      title: "Loyalty",
      link: "loyality-list",
    },
    {
      icons: <i class="fa fa-trash" aria-hidden="true"></i>,
      title: "Interests Management",
      link: "interest-list",
    },
    {
      icons: <i class="fa fa-hdd-o" aria-hidden="true"></i>,
      title: "Holidays",
      link: "holidays-list",
    },
    {
      icons: <i class="fa fa-snowflake-o" aria-hidden="true"></i>,
      title: "Bid Sector",
      link: "bidsector-list",
    },
  ];
  return (
    <div className="OperationsManagementCards smart-admin-crop-container">
      <div className="columns p-4 pt-6 is-multiline">
        {Operatio_cards.map((item) => (
          <div
            className="column is-3 "
            onClick={() => handleNavigate(item?.link)}
          >
            <div className="OperationsManagementCards-card">
              <span className="has-text-link is-size-3">{item.icons}</span>
              <br />
              <span className="">
                <b>{item.title}</b>
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OperationsManagementCards;
