import React, { useEffect, useState } from "react";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { useNavigate } from "react-router-dom";
import {
  SmartSofFile,
  SmartSoftButton,
  SmartSoftCheckRadioSwitch,
  SmartSoftForm,
  SmartValid,
} from "soft_digi";

import {
  ADMIN_USER_LOGO,
  USER_PROFILE_IMAGE,
} from "../../../services/ImageService";
import { getImageContent } from "../../../services/fileService";
import {
  ALLOW_NUMERIC,
  ALLOW_ALPHABET,
} from "../../../services/PatternSerivce.js";

import { get, post } from "../../../services/smartApiService";
import {
  showAlertAutoClose,
  showNotification,
} from "../../../services/notifyService";
import { ADMIN_PROFILE_URLS, USER_URLS } from "../../../api/Admin/AurhUrls";

const AccountSettings = () => {
  const [formData, setFormData] = useState({});
  const [tab, setTab] = useState("account");
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading, closeModal } = useSiteContext();

  const navigate = useNavigate();
  const baseNav = "/account-settings";

  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const loadTableData = () => {
    setLoading(true, "Logging in Please Wait....");
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setLoading(false);
    };
    const subscription = get(USER_URLS.GET_ONE_PROFILE, handleError).subscribe(
      (response) => {
        setFormData(response.data.userData);

        // console.log(response.data);
        //   let mergeObj = {
        //     ...response.data.addrData,
        //     ...response.data.bankData,
        //     ...response.data.userData
        // };
        // setFormData(mergeObj)
        // setTabData(response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  const handleSubmit = () => {
    setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Details Submitting Please Wait....");
    let url = ADMIN_PROFILE_URLS.UPDATE_PROFILE;

    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        // console.log("response form ", response.data);

        setFormData({});
        showAlertAutoClose("Data Saved Successfully", "success");
        loadTableData()

        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const tabs = [
    {
      index: "account",
      label: "Account",
      icon: <i className="fa fa-user-circle-o" aria-hidden="true"></i>,
      link: "account",
    },
    {
      index: "security",
      label: "Security",
      icon: <i className="fa fa-unlock-alt" aria-hidden="true"></i>,
      link: "security",
    },
  ];

  const handleTabs = (index) => {
    setTab(index);
  };

  const tabs_display = () => {
    return (
      <div className="tabs is-boxed is-medium is-8 crop-top-tabs account-settings-tab">
        <ul>
          {tabs.map((item, index) => {
            return (
              <li
                key={index}
                onClick={() => handleTabs(item.index)}
                className={tab === item.index ? "is-active" : ""}
              >
                <a onClick={() => navigateLink(item.link)}>
                  <span>{item.icon}</span>
                  <span className="ml-3">{item.label}</span>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };
  const AccountFormValidations = {
    email_id: [
      SmartValid.required("Please enter a valid Email address"),
      SmartValid.email("Please enter a valid Email address"),
    ],
    name: [SmartValid.required("Name is Required")],
    date: [SmartValid.required("Date is Required")],
    password: [SmartValid.required("Password is Required")],
    Gender: [SmartValid.required("Gender is Required")],

    mobile_no: [
      SmartValid.requiredNumber("Mobile No is Required"),
      // SmartValid.minLength("Number should be minimum 10 digit", 9),
    ],
  };
  const TextFeild = () => {
    const formElements = [
      {
        type: "TEXT_BOX",
        width: "12",
        name: "first_name",
        element: {
          label: "Name",
          placeHolder: "Enter Your Name",
          validations: AccountFormValidations.name,
          inputType: "BORDER_LABEL",
          inputProps: { isFocussed: true },
          max: 200,
          pattern: ALLOW_ALPHABET,
        },
      },

      {
        type: "TEXT_BOX",
        width: "12",
        name: "email_id",
        element: {
          label: "Email",
          placeHolder: "Enter Your Email-Id",
          validations: AccountFormValidations.email_id,
          inputType: "BORDER_LABEL",
          inputProps: { isFocussed: true },
        },
      },

      {
        type: "DATE",
        width: "6",
        name: "age",
        element: {
          label: "Date of Birth",
          validations: AccountFormValidations.date,
          inputType: "BORDER_LABEL",
          inputProps: { isFocussed: true },
        },
      },

      {
        type: "TEXT_BOX",
        width: "6",
        name: "mobile_no",
        element: {
          label: "Phone",
          placeHolder: "Enter Your Phone",
          validations: AccountFormValidations.mobile_no,
          inputType: "BORDER_LABEL",
          inputProps: { isFocussed: true },
          pattern: ALLOW_NUMERIC,
          max: 10,
        },
      },
    ];

    const options = [
      { value: "Male", label: "Male" },
      { value: "Female", label: "Female" },
      { value: "Other", label: "Other" },
    ];

    return (
      <div className="crop-admin-form">
        <div className="mt-5 mr-5">
          <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={formElements}
            formSubmit={formSubmit}
            handleErrorChange={handleErrorChange}
            // onChange={(value) => handleInputChange("input_four", value)}
            // errorEnable={formSubmit}
          />

          <span className="crop-gender-label-color">Gender</span>

          <div className="mr-3 mt-4">
            <SmartSoftCheckRadioSwitch
              isMulti={true}
              options={options}
              type={"radio"}
              name="gender"
              value={formData?.gender || ""}
              onChange={(value) => handleInputChange("gender", value)}
              errorEnable={formSubmit}
              errorUpdate={(value) => handleErrorChange("euserid", value)}
              validations={AccountFormValidations.Gender}
            />
          </div>

          <div className="pt-5">
            <SmartSoftButton
              label="Save Change"
              onClick={() => handleSubmit()}
              classList={["smart-crop-admin-action-button", "px-6 "]}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="smart-admin-crop-container is-mobile">
        <div key={"AccountSettingsFirstcolumns"} className="columns">
          <div key={"AccountSettingsFirstcolumn"} className="column">
            {tabs_display()}
          </div>
        </div>

        <div key={"AccountSettingsSecondcolumns"} className="columns">
          <div
            key={"AccountSettingsSecondcolumn"}
            className="column is-6 is-flex is-justify-content-center "
          >
            <div
              key={"AccountSettingsThirdcolumn"}
              className="column is-12 has-text-centered account-settings-form"
            >
              <span className="is-flex is-justify-content-center">
                <div className="pb-4  ">
                  <figure className="image is-128x128 ">
                    <img
                      className="is-rounded admin-account-image"
                      src={getImageContent(formData?.profile_image)}
                      alt=""
                    />
                  </figure>
                </div>
              </span>
              <span className="is-flex is-justify-content-center">
                <SmartSofFile
                  placeHolder="Upload New Photo"
                  value={formData?.profile_image}
                  onChange={(value) =>
                    handleInputChange("profile_image", value)
                  }
                  errorEnable={formSubmit}
                  fileNameEnable={false}
                />
              </span>
              <span>
                <div className="mt-4 is-size-7">
                  {" "}
                  Allowed PNG or JPEG. Max size of 800K
                </div>
              </span>
            </div>
          </div>

          <div
            key={"AccountSettingsFourthcolumn"}
            className="column is-6 media-screen-AccountSettings"
          >
            {TextFeild()}
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountSettings;
