import React, { useEffect, useState } from 'react'
import UserDetails from './UserDetails'
import Balance from './Balance'
import RevenueOverview from './RevenueOverview'
import Sales from './Sales'
import StatusCard from './StatusCard'
import Offerperform from './Offerperform'
import { useSiteContext } from '../../../contexts/SiteProvider'
import { showAlertAutoClose, showNotification } from '../../../services/notifyService'
import { DASHBOARD_URLS } from '../../../api/Admin/Dashboard'
import { get } from '../../../services/smartApiService'
import { ADMIN_DASHBOARD } from '../../../services/ImageService'

const Dashboard = () => {

  const [tabData, setTabData] = useState([]);
  const { setLoading, openModal, closeModal } = useSiteContext();

  const loadTableData = () => {
    setLoading(true, "Logging in Please Wait....");
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setTabData([]);
      setLoading(false);
    };
    const url= DASHBOARD_URLS.GET_COUNTS
    const year=1
    const subscription = get(
      url+"/"+year,
     
      handleError
    ).subscribe((response) => {
      setTabData(response.data);
      // console.table("testing", response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  return (
    <>
    <div key={"1"} className='columns is-multiline'>
      <div className='column is-6  '>
{/* <UserDetails /> */}
      </div>
      <div className='column is-6 '><Balance /></div>
    </div>
    <div key={"2"} className='columns is-multiline'>
      <div  key={"DashBoardFirstColumn"} className='column is-6 '><RevenueOverview /></div>
      <div  key={"DashBoardSecondColumn"}  className='column is-6 '>
        <div key={"3"} className='columns is-multiline'>
          <div  key={"DashBoardThirdColumn"}  className='column is-12 '><Sales /></div>
          <div  key={"DashBoardFourthColumn"} className='column is-12'>
            <div  key={"DashBoardFirstColumns"} className='columns is-multiline'>
             <StatusCard item={tabData}  />
            </div>
          </div>
        </div>
      </div>
      <div  key={"DashBoardFifthColumn"} className='column  is-6 card'>
        <div className=' has-text-weight-bold'>Market Slots</div>
        
      </div>
      <div  key={"DashBoardSixthColumn"} className='column is-6 card'>
        <img src={ADMIN_DASHBOARD} alt=''/>
      </div>
      <div  key={"DashBoardSeventhColumn"} className='column is-12 card'><Offerperform /></div>
    </div>
    </>
  )
}

export default Dashboard