import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SmartSoftInput, SmartSoftTable } from "soft_digi";
import { SmartSoftCheckRadioSwitch } from "soft_digi";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../services/notifyService";
import { USER_URLS } from "../../../api/Admin/AurhUrls";
import { post } from "../../../services/smartApiService";

import UserProfile from "./UserProfile";
import { encrypt_data } from "../../../services/sessionService";

const CustomerDataBusinessData = () => {
  const [category, setCategory] = useState("1");
  const [data, setTabData] = useState([]);
  const { setLoading, openModal, closeModal } = useSiteContext();
  const loadTableData = () => {
    //setLoading(true, "Logging in Please Wait....");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    let post_data = { category: category };
    let url = USER_URLS.GET_ALL_ADMIN;
    const subscription = post(url, post_data, handleError).subscribe(
      (response) => {
        setTabData(response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, [category]);

  const openNewForm = (data = {}) => {
    // console.log(data);
    let modalObject = {
      body: <UserProfile dataIn={data} />,
      modalClass: "crop-admin-modal smart-modal-80",
      bodyClose: false,
    };
    openModal(modalObject);
  };

  const navigate = useNavigate();
  const baseNav = "/database";

  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const MyOfferSwitch = () => {
    const Customer_Data = [{ value: "1", label: "Customer Data" }];
    const Business_Data = [{ value: "2", label: "Business Data" }];

    // const handleInputChange = (name, value) => {
    //     setFormData((prev) => ({ ...prev, [name]: value }));
    // };
    return (
      <>
        <div className="is-flex">
          <div className="my-offer-month-switch mr-5">
            <SmartSoftCheckRadioSwitch
              options={Customer_Data}
              switchMode={true}
              isRight={true}
              name="switchone"
              value={category}
              onChange={(value) => setCategory("1")}
            />
          </div>
          <div className="my-offer-year-switch">
            <SmartSoftCheckRadioSwitch
              options={Business_Data}
              switchMode={true}
              isRight={true}
              name="switch"
              value={category}
              onChange={(value) => setCategory("2")}
            />
          </div>
        </div>
      </>
    );
  };

  const tagecolor = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
      class: "admin-crop-active-status  is-rounded ",
    },
    {
      label: "PRESUSPENDED",
      value: "PRESUSPENDED",
      class: "admin-crop-presuspended-status is-rounded",
    },
    {
      label: "SUSPENDED",
      value: "SUSPENDED",
      class: "admin-crop-suspended-status  is-rounded ",
    },
    {
      label: "DEACTIVATED",
      value: "DEACTIVATED",
      class: "admin-crop-deactivated-status is-rounded ",
    },
  ];
  // const tageblue = [
  //     {
  //         label: '1085.20',
  //         value: '1085.20',
  //         modalClass: "admin-crop-button-display",
  //         onClick: (data) => {
  //             console.log('data', data);
  //             navigateLink('crops-table')
  //         }
  //     },
  // ]
  const tageblue = (row) => {
    return (
      <div
        className="admin-crop-button-display is-size-7"
        onClick={() => navigateLink("crops-table")}
      >
        {row["crop_balance"]}
      </div>
    );
  };
  const tagecolorbluelight = (row) => {
    return (
      <div
        className="admin-crop-presuspended-status is-size-7 "
        onClick={() => navigateLink("active-offers-table")}
      >
        Active offers
      </div>
    );
  };

  const darkblue = (row) => {
    return (
      <div
        className="admin-prop-button-display "
        onClick={() => navigateLink("props-table")}
      >
        {row["propsline"] || ""}
      </div>
    );
  };
  // const darkblue = [
  //     { label: '4400', value: '4400', class: 'has-background-link is-rounded has-text-white' }
  // ]

  const buttons_profile = [
    {
      label: " ",
      type: "icon",
      leftIcon: "fa fa-user-circle-o ",
      classList: ["smart-edit-icon"],
      onClick: openNewForm,
    },
  ];
  const buttons_invoice = [
    {
      label: " ",
      type: "icon",
      leftIcon: "fa fa-file ",
      classList: ["smart-delete-icon"],
      onClick: (data) => {
        let id =
          data.ID !== undefined
            ? encodeURIComponent(encrypt_data("" + data.ID))
            : "";
        navigateLink("invoice-table/" + id);
      },
    },
  ];
  const buttons_like = [
    {
      label: " ",
      type: "icon",
      leftIcon: "fa fa-thumbs-up ",
      classList: ["smart-like-icon"],
      onClick: (data) => {
        let id =
          data.ID !== undefined
            ? encodeURIComponent(encrypt_data("" + data.ID))
            : "";
        navigateLink("likes-list/" + id);
      },
    },
  ];
  // const tagecolorbluelight = [
  //   {
  //     label: "Active Offers",
  //     value: "1",
  //     class: "admin-crop-presuspended-status  is-rounded ",
  //   },
  // ];
  const buttons_rating = [
    {
      label: " ",
      type: "icon",
      leftIcon: "fa fa-trophy ",
      classList: ["smart-rating-icon"],
      onClick: (data) => {
        let id =
          data.ID !== undefined
            ? encodeURIComponent(encrypt_data("" + data.ID))
            : "";
        navigateLink("rate-product-list/" + id);
      },
    },
  ];
  const buttons_audit_taril = [
    {
      label: " ",
      type: "icon",
      leftIcon: "fa fa-battery-full",
      classList: ["smart-audit-icon"],
      onClick: (data) => {
        let id =
          data.ID !== undefined
            ? encodeURIComponent(encrypt_data("" + data.ID))
            : "";
        navigateLink("customer-audit-trail-list/" + id);
      },
    },
  ];
  const get_table_columns = () => {
    switch (category) {
      case "1":
        return columnsone;
      default:
        return columnstwo;
    }
  };
  const columnsone = [
    { title: "NAME", index: "user_name" },
    { title: "CROP ID", index: "crop_id" },
    { title: "STATUS", index: "active_status", type: "tags", tags: tagecolor },
    //   { title: "TIRE", index: "titleline" },
    {
      title: "PROFILE",
      index: "profileline",
      type: "buttons",
      buttons: buttons_profile,
    },
    {
      title: "INVOICES",
      index: "invoiceline",
      type: "buttons",
      buttons: buttons_invoice,
    },
    { title: "CROPs", index: "crop_balance", valueFunction: tageblue },
    // { title: "PROPs", index: "propsline", valueFunction: darkblue },
    {
      title: "LIKE",
      index: "likeline",
      type: "buttons",
      buttons: buttons_like,
    },
    {
      title: "RATINGS",
      index: "ratingsline",
      type: "buttons",
      buttons: buttons_rating,
    },
    {
      title: "AUDIT TRAIL",
      index: "audittrailline",
      type: "buttons",
      buttons: buttons_audit_taril,
    },
  ];

  // const dataIN = [
  //   {
  //     nameline: "Aishu Home Kumer",
  //     cropidline: "100004",
  //     statusline: "1",
  //     titleline: "A",
  //     profileline: "",
  //     invoiceline: "",
  //     cropsline: "1085.20",
  //     propsline: "4400",
  //     likeline: "",
  //     activeofferline: "1",
  //     ratingsline: "",
  //     audittrailline: "",
  //   },
  //   {
  //     nameline: " Kumer",
  //     cropidline: "100004",
  //     statusline: "2",
  //     titleline: "B",
  //     profileline: "",
  //     invoiceline: "",
  //     cropsline: "1085.20",
  //     propsline: "4400",
  //     likeline: "",
  //     activeofferline: "1",
  //     ratingsline: "",
  //     audittrailline: "",
  //   },
  //   {
  //     nameline: "Veer ahema Aishu Kumar",
  //     cropidline: "100004",
  //     statusline: "3",
  //     titleline: "C",
  //     profileline: "",
  //     invoiceline: "",
  //     cropsline: "1085.20",
  //     propsline: "4400",
  //     likeline: "",
  //     activeofferline: "1",
  //     ratingsline: "",
  //     audittrailline: "",
  //   },
  //   {
  //     nameline: "Gopinath jaya kumar",
  //     cropidline: "100004",
  //     statusline: "6",
  //     titleline: "D",
  //     profileline: "",
  //     invoiceline: "",
  //     cropsline: "1085.20",
  //     propsline: "4400",
  //     likeline: "",
  //     activeofferline: "1",
  //     ratingsline: "",
  //     audittrailline: "",
  //   },
  //   {
  //     nameline: "Hema kumar",
  //     cropidline: "100004",
  //     statusline: "5",
  //     titleline: "E",
  //     profileline: "",
  //     invoiceline: "",
  //     cropsline: "1085.20",
  //     propsline: "4400",
  //     likeline: "",
  //     activeofferline: "1",
  //     ratingsline: "",
  //     audittrailline: "",
  //   },
  //   {
  //     nameline: "Gopinath",
  //     cropidline: "100004",
  //     statusline: "6",
  //     titleline: "F",
  //     profileline: "",
  //     invoiceline: "",
  //     cropsline: "1085.20",
  //     propsline: "4400",
  //     likeline: "",
  //     activeofferline: "1",
  //     ratingsline: "",
  //     audittrailline: "",
  //   },
  // ];

  const Sesrchbtn = () => {
    const handleInputChange = (name, value) => {
      //console.log(" name " ,name , "   value " , value)
      setFormData((prev) => ({ ...prev, [name]: value }));
    };
    const [formData, setFormData] = useState({});
    const [formSubmit, setFormSubmit] = useState(false);
    const [formErrors, setFormErrors] = useState(false);
    const handleErrorChange = (name, value) => {
      setFormErrors((prev) => {
        // Create a copy of the previous state
        const updatedFormData = { ...prev };
        // Check if the value is null or empty
        if (value === null || value === "") {
          // If true, remove the property from the updated state
          delete updatedFormData[name];
        } else {
          // Otherwise, update or add the property in the updated state
          updatedFormData[name] = value;
        }
        return updatedFormData;
      });
    };

    return (
      <>
        <div className="holiday-update-form crop-admin-form">
          <SmartSoftInput
            label="Search CROP ID"
            value={formData?.holiday_name || ""}
            rightIcon="fa fa-search"
            inputType="BORDER_LABEL"
            onChange={(value) => handleInputChange("holiday_name", value)}
            errorEnable={formSubmit}
          />
        </div>
      </>
    );
  };

  const columnstwo = [
    { title: "NAME", index: "user_name" },
    { title: "CROP ID", index: "crop_id" },
    { title: "STATUS", index: "active_status", type: "tags", tags: tagecolor },
    // { title: "TIRE", index: "titleline" },
    {
      title: "PROFILE",
      index: "profileline",
      type: "buttons",
      buttons: buttons_profile,
    },
    {
      title: "INVOICES",
      index: "invoiceline",
      type: "buttons",
      buttons: buttons_invoice,
    },

    { title: "CROPs", index: "crop_balance", valueFunction: tageblue },

    {
      title: "ACTIVE OFFERS",
      index: "activeofferline",
      valueFunction: tagecolorbluelight,
    },
    {
      title: "RATINGS",
      index: "ratingsline",
      type: "buttons",
      buttons: buttons_rating,
    },
    {
      title: "AUDIT TRAIL",
      index: "audittrailline",
      type: "buttons",
      buttons: buttons_audit_taril,
    },
  ];

  return (
    <div className="smart-crop-admin-container">
      <div key={"CustomerDataBusinessDataone"} className="columns">
        <div key={"CustomerDataBusinessDatatwo"} className="column">
          <div key={"CustomerDataBusinessDatacardone"} className="card">
            <div className="">
              <div
                key={"CustomerDataBusinessDatathree"}
                className="columns is-vcentered "
              >
                <div
                  key={"CustomerDataBusinessDatacolumnone"}
                  className="column ml-6 is-8 crop-admin-table-title"
                >
                  {MyOfferSwitch()}
                </div>

                <div
                  key={"CustomerDataBusinessDatatwo"}
                  className="column is-flex  "
                >
                  <div className="crop-admin-form">{Sesrchbtn()}</div>
                  {/* <i className="fa fa-caret-left" aria-hidden="true"></i>{" "}
                                <i className="fa fa-caret-right" aria-hidden="true"></i> */}
                </div>
              </div>
              <SmartSoftTable
                columns={get_table_columns()}
                data={data}
                tableProps={{ className: "crop-table-layout" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDataBusinessData;
