import React from "react";
import { ADMIN_MARKET_ICON } from "../../../services/ImageService";

const StatusCard = ({ item }) => {
  console.log("items For test", item);

  return (
    <>
      <div  key={"StatusCardFirstcolumn"}  className="column is-6 ">
        <div  key={"StatusCardFirstcard"}  className="smart-crop-dashboard-container card p-3 pb-3">
          <div className="has-text-weight-semibold">Total Customers</div>
          <div className="is-flex">
            <div className="dashboard-status-icon">
              {" "}
              <i className="fa fa-user-circle-o" aria-hidden="true"></i>
            </div>

            <div className=" has-text-weight-bold mt-2 ml-2">
              {item.totalCus}
            </div>
          </div>
        </div>
      </div>
      <div  key={"StatusCardSecondcolumn"} className="column is-6 ">
        <div  key={"StatusCardSecondcard"}  className="smart-crop-dashboard-container card p-3 pb-3">
          <div className="has-text-weight-semibold">Active Customers</div>
          <div className="is-flex">
            <div className="dashboard-status-icon">
              {/* <i class="fa fa-user-circle-o" aria-hidden="true"></i> */}
              <img
                src={ADMIN_MARKET_ICON}
                alt="admin_market_icon"
                className="image is-32x32 mt-2 is-rounded"
              />
            </div>

            <div className=" has-text-weight-bold mt-2 ml-2">
              {item.totalActCus}
            </div>
          </div>
        </div>
      </div>
      <div  key={"StatusCardThirdcolumn"} className="column is-6">
        <div  key={"StatusCardThirdcard"} className="smart-crop-dashboard-container card p-3 pb-3">
          <div className="has-text-weight-semibold">Total Businesses</div>
          <div className="is-flex">
            <div className="dashboard-status-icon">
              {" "}
              <i className="fa fa-user-circle-o" aria-hidden="true"></i>
            </div>

            <div className=" has-text-weight-bold mt-2 ml-2">
              {item.totalBus}
            </div>
          </div>
        </div>
      </div>
      <div  key={"StatusCardFourthcolumn"}  className="column is-6 ">
        <div  key={"StatusCardFourthcard"}  className="smart-crop-dashboard-container card p-3 pb-3">
          <div className="has-text-weight-semibold">Active Businesses</div>
          <div className="is-flex">
            <div className="dashboard-status-icon">
              <img
                src={ADMIN_MARKET_ICON}
                alt="admin_market_icon"
                className="image is-32x32 mt-2 is-rounded"
              />
            </div>

            <div className=" has-text-weight-bold mt-2 ml-2">
              {item.totalActBus}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatusCard;
