import React, { useState } from 'react'
import { SmartSoftCheckRadioSwitch,SmartSoftTable } from "soft_digi";

const Offerperform = () => {
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const [category, setCategory] = useState("1");

    const MyOfferSwitch = () => {
  
        const Customer_services = [{ value: "1", label: "High Performance" }];
        const Business_services = [{ value: "2", label: "Low Performance" }];
    
        const handleInputChange = (name, value) => {
          setFormData((prev) => ({ ...prev, [name]: value }));
        };
        return (
          <>
          <div  key={"OfferPerformanceFirstColumn"} className="column is-8 crop-admin-table-title">
                  Offer Performance
                </div>
            <div className="is-flex">
              <div className="my-offer-month-switch mr-5">
                <SmartSoftCheckRadioSwitch
                  options={Customer_services}
                  switchMode={true}
                  isRight={true}
                  name="switchone"
                  value={category}
                  onChange={(value) => setCategory("1")}
                />
              </div>
              <div className="my-offer-year-switch">
                <SmartSoftCheckRadioSwitch
                  options={Business_services}
                  switchMode={true}
                  isRight={true}
                  name="switch"
                  value={category}
                  onChange={(value) => setCategory("2")}
                />
              </div>
            </div>
          </>
        );
      };






    const columns = [
        { title: "Offer", index: "item", width: "20" },
        { title: "Sector", index: "type", width: "20" },
        { title: "Business Name", index: "name", width: "20" },
        { title: "Quantity", index: "quantity", width: "20" },
        { title: "Sale Value", index: "amount", width: "20" },
        
    ]

    const TableData = [
        {
          item: "Bed Sheet",
          type: "Retail",
          name: "k-Mart",
          quantity: "2",
          amount:50.00
        },
        {
            item: "Bed Sheet",
            type: "Shop",
            name: "k-Mart",
            quantity: "1",
            amount:60.00
          },

          {
            item: "Bed Sheet",
            type: "Dine",
            name: "k-Mart",
            quantity: "2",
            amount:80.00
          },

          {
            item: "Bed Sheet",
            type: "Retail",
            name: "k-Mart",
            quantity: "2",
            amount:50.00
          },
  

    ]
  return (
    <>
    <div>
    <div key={"OfferPerformanceSecondColumn"} className="column is-8 crop-admin-table-title">
                  {MyOfferSwitch()}
                </div>
    </div>
      <div>
        <SmartSoftTable
          columns={columns}
          data={TableData}
          tableProps={{ className: "crop-table-layout" }}
        />
      </div>
    </>
  );
};
export default Offerperform;
