import React, { useEffect, useState } from "react";
import {
  SmartSoftButton,
  SmartSoftDate,
  SmartSoftInput,
  SmartSoftTable,
  SmartValid,
} from "soft_digi";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import LoyalityForm from "../Loyality/LoyalityForm";
import { ADMIN_OPERATIONS_HOLIDAYS } from "../../../../api/Admin/OperationsUrl";
import { showAlertAutoClose, showNotification } from "../../../../services/notifyService";
import { get, post } from "../../../../services/smartApiService";
import { useNavigate } from "react-router-dom";

const HolidayUpdateForm = ({ loadTableData, editData }) => {
  const [formData, setFormData] = useState({
    holiday_name: "",
    holiday_date: "",
    state_name: "",
  });
  const [formErrors, setFormErrors] = useState(false);
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const [formSubmit, setFormSubmit] = useState(false);
  const { setLoading, closeModal } = useSiteContext();

  useEffect(() => {
    if (editData) {
      setFormData(editData);
    }
  }, [editData]);

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleSubmit = () => {
    setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose("error", errorMessage);
      setLoading(false);
    };
    setLoading(true, "Details Submitting Please Wait....");
    let url = ADMIN_OPERATIONS_HOLIDAYS.INSERT;
    if (formData.ID !== undefined) {
      formData["id"] = formData.ID;
      url = ADMIN_OPERATIONS_HOLIDAYS.UPDATE;
    }

    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        showAlertAutoClose("Data Saved Successfully","success");
        setFormData({ holiday_name: "", holiday_date: "", state_name: "" });
        loadTableData();
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };
  const HolidaysFormValidations = {
    state: [SmartValid.required("state is Required")],
    name: [SmartValid.required("Name is Required")],
    date: [SmartValid.required("Date is Required")],

    // SmartValid.minLength("Number should be minimum 10 digit", 9),
  };
  return (
    <>
      <div className="m-4">
        <SmartSoftInput
          label=" Name"
          value={formData?.holiday_name || ""}
          inputType="BORDER_LABEL"
          onChange={(value) => handleInputChange("holiday_name", value)}
          errorEnable={formSubmit}
          errorUpdate={(value) => handleErrorChange("euserid", value)}
          validations={HolidaysFormValidations.name}
        />
      </div>
      <div className="m-4">
        <SmartSoftDate
          label="Date"
          value={formData?.holiday_date || ""}
          inputType="BORDER_LABEL"
          onChange={(value) => handleInputChange("holiday_date", value)}
          errorEnable={formSubmit}
          errorUpdate={(value) => handleErrorChange("euserid", value)}
          validations={HolidaysFormValidations.date}
        />
      </div>
      <div className="m-4">
        <SmartSoftInput
          label=" State"
          value={formData?.state_name || ""}
          inputType="BORDER_LABEL"
          onChange={(value) => handleInputChange("state_name", value)}
          errorEnable={formSubmit}
          errorUpdate={(value) => handleErrorChange("euserid", value)}
          validations={HolidaysFormValidations.state}
        />
      </div>
      <div className="m-4">
        <SmartSoftButton
          classList={["smart-crop-admin-action-button"]}
          label="Save"
          onClick={handleSubmit}
          errorEnable={formSubmit}
          errorUpdate={(value) => handleErrorChange("euserid", value)}
          validations={HolidaysFormValidations}
        />
      </div>
    </>
  );
};

const HolidaysTable = () => {
  const navigate = useNavigate();

  const baseNav = "/operations";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const [tabData, setTabData] = useState([]);
  const { setLoading, openModal, closeModal } = useSiteContext();
  const [editData, setEditData] = useState(null);
  // this is api to the table data
  const loadTableData = () => {
    setLoading(true, "Logging in Please Wait....");

    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setTabData([]);
      setLoading(false);
    };
    const subscription = get(
      ADMIN_OPERATIONS_HOLIDAYS.GET_ALL,
      handleError
    ).subscribe((response) => {
      setTabData(response.data);
      // console.table("testing", response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  const edit_buttons = [
    {
      label: "",
      leftIcon: "fa-pencil",
      type: "icon",
      classList: ["smart-edit-icon"],
      onClick: (data) => {
        viewEditForm(data["ID"]);
        //viewEditData(data["ID"],"VIEW");
      },
    },
  ];
  const delete_buttons = [
    {
      label: "",
      leftIcon: "fa-trash",
      type: "icon",
      classList: ["smart-delete-icon"],
      onClick: (data) => {
        openDeleteModal(data["ID"]);
        //viewEditData(data["ID"],"VIEW");
      },
    },
  ];
  const viewEditForm = (id, action_name) => {
    setLoading(true, "Please Wait....");
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setLoading(false);
    };
    const subscription = post(
      ADMIN_OPERATIONS_HOLIDAYS.GET_ONE,
      { id: id },
      handleError
    ).subscribe((response) => {
      // console.log("response ", response);
      setEditData(response.data);
      // console.log(editData);
      // openNewForm(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };
  const openDeleteModal = (id, name) => {
    let modelObject = {
      title: "Do you want to Delete?",
      body: "Note: The user will be deleted! Action cannot be reverted",
      okFunction: () => {
        deleteData(id);
      },
      cancelFunction: () => {
        closeModal();
        // console.log("cancel function")
      },
    };
    openModal(modelObject);
  };
  const deleteData = (id) => {
    setLoading(true, "Please Wait....");
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setLoading(false);
    };
    const subscription = post(
      ADMIN_OPERATIONS_HOLIDAYS.DELETE,
      { id: id },
      handleError
    ).subscribe((response) => {
      showAlertAutoClose("Deleted Successfully...","success");
      closeModal();
      loadTableData();
      // setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const columns = [
    // { title: "", index: "testnew", width: "20" },
    { title: "STATE", index: "state_name", isSortable: true, width: "20" },
    { title: "DATE", index: "holiday_date", isSortable: true },
    { title: "NAME", index: "holiday_name", isSortable: true, width: "20" },
    { title: "EDIT", index: "edit", type: "buttons", buttons: edit_buttons },
    {
      title: "DELETE",
      index: "delete",
      type: "buttons",
      buttons: delete_buttons,
    },
  ];
  const data = [];

  const openNewForm = () => {
    let modalObject = {
      body: <LoyalityForm loadTableData={loadTableData} dataIn={data} />,
      modalClass: "crop-admin-modal",
    };
    openModal(modalObject);
  };

  return (
    <>
      <div className="smart-crop-admin-container is-mobile">
        <div key={"HolidaysTableFirstcolumns"} className="columns">
          <div key={"HolidaysTableFirstcolumn"} className="column ">
            <div className="card">
              <div
                key={"HolidaysTableSecondcolumns"}
                className="columns is-vcentered"
              >
                <div
                  key={"HolidaysTableSecondcolumn"}
                  className="column is-1 crop-admin-table-back"
                >
                  <span className="icon" onClick={() => navigateLink("home")}>
                    <i className="fa fa-undo" aria-hidden="true"></i>
                  </span>
                </div>
                <div
                  key={"HolidaysTableThirdcolumn"}
                  className="column is-8 crop-admin-table-title"
                >
                  Holidays
                </div>

                <div
                  key={"HolidaysTableFourthcolumn"}
                  className="column is-3 has-text-right crop-table-top-pagination"
                >
                  {/* <i className="fa fa-caret-left" aria-hidden="true"></i>{" "} */}
                  {/* <i className="fa fa-caret-right" aria-hidden="true"></i> */}
                </div>
              </div>
              <div key={"HolidaysTableThirdcolumns"} className="columns   mr-0">
                <div key={"HolidaysTableFifthcolumn"} className="column is-6 ">
                  <SmartSoftTable
                    columns={columns}
                    data={tabData}
                    tableProps={{ className: "crop-table-layout" }}
                  />
                </div>
                <div
                  key={"HolidaysTablesixthcolumn"}
                  className="column is-6 card p-0 mt-3  smart-holiday-update-card "
                >
                  <div className="smart-crop-admin-holiday-card pl-5">
                    Add New Holiday
                  </div>
                  <div className="holiday-update-form crop-admin-form">
                    <HolidayUpdateForm
                      loadTableData={loadTableData}
                      editData={editData}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HolidaysTable;
