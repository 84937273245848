import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { SmartSoftForm } from "soft_digi";
import { DATABASE_URLS, USER_URLS } from "../../../api/Admin/AurhUrls";
import { post } from "../../../services/smartApiService";
import { showAlertAutoClose } from "../../../services/notifyService";
import {
  ADMIN_USER_LOGO,
  CROP_ADMIN_PROFILE_IMAGE,
  USER_PROFILE_IMAGE,
} from "../../../services/ImageService";

const UserProfile = ({ dataIn }) => {
  const [formData, setFormData] = useState(null);
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading, closeModal } = useSiteContext();
  // const navigate = useNavigate();

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };
  const loadTableData = () => {
    //setLoading(true, "Logging in Please Wait....");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    let post_data = { id: dataIn.ID };
    let url = DATABASE_URLS.GET_ALL_PROFILE;
    const subscription = post(url, post_data, handleError).subscribe(
      (response) => {
        // console.log(response.data);
        setFormData(response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  const numericValidations = [
    {
      type: "required",
      msg: "Please enter correct value",
    },
  ];

  const handleFormSubmit = () => {
    // console.log("formdata", formData);
    setFormSubmit(true);
    // console.log("form errors ", formErrors);
  };

  const getValue = (index) => {
    // console.log("form data " , formData);
    // console.log("index = " , index , "   " , formData[index]);
    let output = formData[index] !== undefined ? formData[index] : "";
    //console.log(output)
    return output instanceof Object || Array.isArray(output) ? "" : output;
  };

  const Header = () => {
    const costomeruserheader = [
      { title: "Customer Name", index: "first_name", key: "1" },
      { title: "CROP ID", index: "crop_id", key: "2" },
      { title: "Gender", index: "gender", key: "3" },
      // { title: "Loyalty", textname: "True",index:"first_name" },
      { title: "DOB", index: "age", key: "4" },
      // { title: "Interest", textname: "True",index:"first_name" },
      { title: "Age Group", index: "age_gr", key: "5" },
      { title: "Marketing Notification", index: "marketting", key: "6" },
      { title: "Mobile Number", index: "mobile_no", key: "7" },
      { title: "Newsletter Status", index: "newsletter", key: "8" },
      { title: "Email ID", index: "email_id", key: "9" },
      // { title: "Tier", textname: "Blue",index:"first_name" },
    ];
    return (
      <>
        {formData && (
          <div key={"UserProfilecolumnsone"} className="columns is-multiline ">
            {costomeruserheader.map((item) => (
              <div
                key={"UserProfilecolumnone"}
                className="column is-5 inputbox-type-header-UserProfile m-3 "
              >
                <span className="inputbox-type-header-UserProfile-position ">
                  {item.title}
                </span>
                <span className="has-text-black textposition-UserProfile">
                  {getValue(item.index)}
                </span>
              </div>
            ))}
          </div>
        )}
      </>
    );
  };
  const costomeruserheadertwo = () => {
    const costomeruserheaderarry = [
      { title: "Referral Code", textname: "  Svwih-GK", index: "" },
      { title: "Customer Singup Date", textname: "  04/10/2023", index: "" },
      { title: "Last Tier Change Date", textname: "  04/10/2023", index: "" },
      { title: "Promo Code", textname: "  Promo Code" },
    ];
    return (
      <>
        {/* <div className="columns"> */}
        <div className=" ">
          <div key={"UserProfilecolumnstwo"} className="columns is-multiline">
            {costomeruserheaderarry.map((item) => (
              <div
                key={"UserProfilecolumntwo"}
                className="column is-10 inputbox-type-header-UserProfile has-text-left m-3"
              >
                <span className="inputbox-type-header-UserProfile-position">
                  {item.title}
                </span>
                <span className="has-text-black textposition-UserProfile">
                  {item.textname}
                </span>
              </div>
            ))}
            <div
              key={"UserProfilecolumnthree"}
              className="column is-11 has-text-left "
            >
              <div key={"two"} className=" ">
                <div className=" textareatype-UserProfile">
                  <span className="input-type-name-texterea">Address 1</span>
                  <span className="has-text-black textposition-UserProfile">
                    <ul>
                      <li>Line1:73 Cecil Street</li>
                      <li>Line2:Dundas Velley</li>
                      <li>State:New South Wales</li>
                      <li>Pin:4566</li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div key={"UserProfilecolumnsthree"} className="columns">
      <div className="crop-admin-form  smart-admin-crop-container">
        <div
          key={"UserProfilecolumnfour"}
          className="column is-12 has-text-centered "
        >
          <div className="admin-database-profile-images">
            <div className=" image database-profile-image">
              <img
                className="database-profile"
                src={CROP_ADMIN_PROFILE_IMAGE}
                alt=""
              />
            </div>
            <div className="">
              <figure className="image is-128x128">
                <img
                  className="is-rounded admin-account-image database-user-image"
                  src={ADMIN_USER_LOGO}
                  alt=""
                />
              </figure>
            </div>
            <div className="is-flex is-justify-content-center ml-5 is-size-4 has-text-weight-bold">
              Profile Details
            </div>
          </div>
        </div>

        <div key={"UserProfilecolumnsfour"} className="columns  ">
          <div
            key={"UserProfilecolumnfive"}
            className="column mediaUserProfileheader is-6 ml-5 pl-2 mt-6 mb-6 "
          >
            {Header()}
          </div>
          <div key={"UserProfilecolumnsix"} className="column  is-6">
            <div key={"UserProfilecolumnsfive"} className="columns ">
              <div className="column mediaUserProfilecostomeruserheadertwo is-6 mt-6 pt-3">
                {costomeruserheadertwo()}
              </div>

              <div
                key={"UserProfilecolumnseven"}
                className="column mediaUserProfile is-6 mt-6  p-5 mb-6"
              >
                <div key={"oneone"} className="columns ">
                  <div
                    key={"UserProfilecolumneight"}
                    className="column is-8 mt-1 textareatype-UserProfile-size"
                  >
                    <span className="input-type-name-texterea">Interests</span>
                    <span className="has-text-black pl-3">
                      <ul>
                        <li>Book Reading</li>
                        <li>Music</li>
                        <li>Dance</li>
                      </ul>
                    </span>
                  </div>
                </div>
                <div key={"twotwo"} className="columns ">
                  <div
                    key={"UserProfilecolumnnine"}
                    className="column  is-8 mt-5 textareatype-UserProfile-size"
                  >
                    <span className="input-type-name-texterea">Loyalty</span>
                    <span className="has-text-black pl-3">
                      <ul>
                        <li>Loyalty1</li>
                        <li>Loyalty2</li>
                        <li>Loyalty3</li>
                        <li>Loyalty4</li>
                      </ul>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div key={"UserProfilecolumnten"} className="column">
        <div className="crop-admin-modal-close" onClick={closeModal}>
          <i className="fa fa-times" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
