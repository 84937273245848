import React, { useState, useEffect } from "react";
import { post } from "../../../services/smartApiService";
import { getImageContent } from "../../../services/fileService";
import "./SmartFormElements.css";
const SmartImageDisplay = (props) => {
  const {
    srcType = "DATA",
    url = "",
    data,
    id = 0,
    imageClass = "",
    isMulti = false,
    updateImages
  } = props;
//  console.log("props  ", props);
  const [src, setSrc] = useState(data);

  const loadImageData = () => {
    // setLoading(true, "Logging in Please Wait....");
    const handleError = (errorMessage) => {
      setSrc("");
    };
    const subscription = post(url, { id: id }, handleError).subscribe(
      (response) => {
        // console.log(response);
        setSrc(response.data.content);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    if (srcType === "URL" && url.length > 10) {
      loadImageData();
    }else{
      setSrc(data);
    }
  }, [props]);

  const singleImage = () => {
    return (
      <figure class={"image " + imageClass}>
        <img src={src ? `data:image/png;base64,${src}` : ""} />
      </figure>
    );
  };

  const deleteImage=(indexToRemove)=>{
    const updatedItems = [...src];
    // Remove the element at the specified index
    updatedItems.splice(indexToRemove, 1);
    //
    if(updateImages)
      updateImages(updatedItems);
  }

  const edit_options=(index)=>{
    return (
      <div className="image-edit-options">       
        <i className="fa fa-file has-text-link"></i>
        <i className="fa fa-trash has-text-danger" onClick={()=>deleteImage(index)}></i>
      </div>
    )
  }

  const singleImageDisplay=(item,index)=>{
     return <div className={"column smart-image-display-options "+ imageClass} key={index}>
        <img src={getImageContent(item)} />
      {edit_options(index)}
     </div>
  }

  const multipleImages = () => {
    return(
      <div className="columns is-multiline">
        {
          src.map((item,index)=>{
          return  singleImageDisplay(item,index)
          })
        }
      </div>
    )
    
  };

  return isMulti ? multipleImages() : singleImage();
};

export default SmartImageDisplay;
