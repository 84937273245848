import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import OperationsHome from "../../pages/Admin/Operations/OperationsHome";
import {
  LoyalityTable,
  OperationsManagementCards,
  ManagementRequest,
  ManagementService,
  CompaintTable,
  EarnCropsTable,
  Marcom,
  HolidaysTable,
  LoyalityForm,
  InterestForm,
  InterestTable,
  SectorTable,
  PaymentTable,
  PaymentHistoryTable,
  ManagementRequestForm,
  CropManagementForm,
  TierTable,
  TierForm,
  Status,
  UpdatePopup,
} from "../../pages/Admin/index";
import TierStatus from "../../pages/Admin/Operations/Tier/TierStatus";
import BidSectorTable from "../../pages/Admin/Operations/BidSector/BidSectorTable";

const OperationsRoute = () => {
  return (
    <Routes>
      <Route path="/loyality-list" element={<LoyalityTable />} />
      <Route path="/loyality-form" element={<LoyalityForm />} />
      <Route path="/interest-form" element={<InterestForm />} />
      <Route path="/home" element={<OperationsManagementCards />} />
      <Route path="/management-services-list" element={<ManagementService />} />
      <Route path="/management-request-list" element={<ManagementRequest />} />
      <Route path="/request-form" element={<ManagementRequestForm />} />
      <Route path="/operations-compaint-list" element={<CompaintTable />} />
      <Route path="/earn-crops-list" element={<EarnCropsTable />} />
      <Route path="/holidays-list" element={<HolidaysTable />} />
      <Route path="/sector-list" element={<SectorTable />} />
      <Route path="/interest-list" element={<InterestTable />} />
      <Route path="/payment-list" element={<PaymentTable />} />
      <Route path="/payment-history-list" element={<PaymentHistoryTable />} />
      <Route path="/crop-management" element={<CropManagementForm />} />
      <Route path="/tier-list" element={<TierTable />} />
      <Route path="/tier-form" element={<TierForm />} />
      <Route path="/status" element={<Status />} />
      <Route path="/tire-status" element={<TierStatus />} />
      <Route path="/bidsector-list" element={<BidSectorTable />} />
      <Route path="/update-popup-form" element={<UpdatePopup />} />
    </Routes>
  );
};

export default OperationsRoute;
