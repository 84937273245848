import { useState } from "react";
import { SmartSoftInput, SmartSoftSelect, SmartSoftTable } from "soft_digi";
import { SmartSoftCheckRadioSwitch } from "soft_digi";
import { useNavigate } from "react-router-dom";
import { FOLDER_ICON } from "../../../../services/ImageService";
import { useSiteContext } from "../../../../contexts/SiteProvider";

const EarnCropsTable = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState("1");
  const [tab, setTab] = useState("MOST_POPULAR");
  const { setLoading, openModal, closeModal } = useSiteContext();

  const baseNav = "/operations";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const tabs = [
    { index: "MOST_POPULAR", label: "MOST POPULAR" },
    { index: "STAR_RATING", label: "STAR RATING" },
    { index: "NEAR_ME", label: "NEAR ME" },
  ];

  const handleTabs = (index) => {
    setTab(index);
  };

  const tabs_display = () => {
    return (
      <div class="tabs is-boxed crop-top-tabs">
        <ul>
          {tabs.map((item, index) => {
            return (
              <li
                key={index}
                onClick={() => handleTabs(item.index)}
                className={tab === item.index ? "is-active" : ""}
              >
                <a href="#">
                  <span className="pr-6 pl-6">{item.label}</span>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const get_table_Data = () => {
    switch (tab) {
      case "MOST_POPULAR":
        return MostEarnCropsTableData;
      case "STAR_RATING":
        return StarEarnCropsTableData;
      case "NEAR_ME":
        return NearEarnCropsTableData;
      default:
        return MostEarnCropsTableData;
    }
  };

  const get_url = () => {
    switch (tab) {
      case "MOST_POPULAR":
        return MostRedeemCropsTableData;
      case "STAR_RATING":
        return StarRedeemCropsTableData;
      case "NEAR_ME":
        return NearRedeemCropsTableData;
      default:
        return MostRedeemCropsTableData;
    }
  };

  const MyOfferSwitch = () => {
    const [formData, setFormData] = useState({});
    const [formSubmit, setFormSubmit] = useState(false);
    const [formErrors, setFormErrors] = useState(false);
    const earn_crops = [{ value: "1", label: "Earn CROPs" }];
    const redeem_crops = [{ value: "2", label: "Redeem CROPs" }];

    const handleInputChange = (name, value) => {
      setFormData((prev) => ({ ...prev, [name]: value }));
    };
    return (
      <>
        <div className="is-flex">
          <div className="my-offer-month-switch mr-5">
            <SmartSoftCheckRadioSwitch
              options={earn_crops}
              switchMode={true}
              isRight={true}
              name="switchone"
              value={category}
              onChange={(value) => setCategory("1")}
            />
          </div>
          <div className="my-offer-year-switch">
            <SmartSoftCheckRadioSwitch
              options={redeem_crops}
              switchMode={true}
              isRight={true}
              name="switch"
              value={category}
              onChange={(value) => setCategory("2")}
            />
          </div>
        </div>
      </>
    );
  };

  const nameFunction = () => {
    return (
      <div className="is-flex">
        <figure className="image is-32x32 mr-2">
          <img src={FOLDER_ICON} alt="folder-icon" />
        </figure>
      </div>
    );
  };

  const openDeleteModal = (id, name) => {
    let modelObject = {
      title: "Do you want to Delete?",
      body: "Note: The user will be deleted! Action cannot be reverted",
      okFunction: () => {
        // deleteData(id);
      },
      cancelFunction: () => {
        closeModal();
        // console.log("cancel function")
      },
    };
    openModal(modelObject);
  };

  const nameTags = [
    {
      value: "active",
      label: "Active",
      class: ["admin-crop-active-status is-rounded is-small m-0"],
    },
    {
      value: "scheduled",
      label: "Scheduled",
      class: ["is-warning is-rounded is-small m-0"],
    },
  ];

  const nameButtons = [
    {
      label: "Delete",
      classList: ["is-danger is-rounded is-small m-0"],
      onClick: ()=>{
        openDeleteModal();
      }
    },
  ];

  const columns = [
    { title: "SECTOR", index: "sector" },
    { title: "PRODUCT", index: "product", valueFunction: nameFunction },
    { title: "TITLE", index: "title" },
    { title: "RATING", index: "rating" },
    { title: "LIKES", index: "likes" },
    { title: "AUD", index: "aud" },
    { title: "CROPs", index: "crops" },
    {
      title: "FROM",
      index: "from",
      type: "date",
      dateFormat: "DD/MM/YYYY",
    },
    {
      title: "TO",
      index: "to",
      type: "date",
      dateFormat: "DD/MM/YYYY",
    },
    { title: "STATUS", index: "status", type: "tags", tags: nameTags },
    { title: "ACTION", index: "action", type: "buttons", buttons: nameButtons },
  ];

  // MOST POPULAR EarnCropsTableData
  const MostEarnCropsTableData = [
    {
      sector: "Fuel",
      product: "",
      title: "I Mac",
      rating: 0,
      likes: 0,
      aud: 12600,
      crops: 12600,
      from: "2023/06/01",
      to: "2023/06/30",
      status: "active",
    },
    {
      sector: "Fuel",
      product: "",
      title: "I Mac",
      rating: 0,
      likes: 0,
      aud: 12600,
      crops: 12600,
      from: "2023/06/01",
      to: "2023/06/30",
      status: "scheduled",
    },
  ];

  // MOST POPULAR RedeemCropsTableData
  const MostRedeemCropsTableData = [
    {
      sector: "Shops",
      product: "",
      title: "I Mac",
      rating: 0,
      likes: 0,
      aud: 96,
      crops: 9.6,
      from: "2023/06/01",
      to: "2023/06/30",
      status: "scheduled",
    },
  ];

  //STAR RATING EarnCropsTableDate
  const StarEarnCropsTableData = [
    {
      sector: "Shops",
      product: "",
      title: "Title",
      rating: 4.4,
      likes: 80,
      aud: 359.1,
      crops: 359.1,
      from: "2023/05/01",
      to: "2023/06/30",
      status: "scheduled",
    },
  ];

  //STAR RATING RedeemCropsTableDate
  const StarRedeemCropsTableData = [
    {
      sector: "Shops",
      product: "",
      title: "Toy",
      rating: 0,
      likes: 0,
      aud: 0,
      crops: 0,
      from: "2023/04/25",
      to: "2023/06/30",
      status: "scheduled",
    },
  ];

  //NEAR ME NearEarnCropsTableData
  const NearEarnCropsTableData = [
    {
      sector: "Shops",
      product: "",
      title: "Ear Phone",
      rating: 4.4,
      likes: 80,
      aud: 576,
      crops: 359.1,
      from: "2023/05/01",
      to: "2023/06/30",
      status: "scheduled",
    },
  ];

  //NEAR ME NearRedeemCropsTableData
  const NearRedeemCropsTableData = [
    {
      sector: "Shops",
      product: "",
      title: "Toy",
      rating: 0,
      likes: 0,
      aud: 0,
      crops: 0,
      from: "2023/04/25",
      to: "2023/06/30",
      status: "scheduled",
    },
  ];

  const options = [
    { value: "1", label: "Top Rank" },
    { value: "2", label: "Promos" },
    { value: "3", label: "None" },
  ];

  return (
    <>
      <div className="smart-crop-admin-container">
        <div className="columns">
          <div className="column">
            <div className="card">
              <div className="columns is-vcentered">
                <div className="column is-1 crop-admin-table-back">
                  <span className="icon" onClick={() => navigateLink("home")}>
                    <i className="fa fa-undo" aria-hidden="true"></i>
                  </span>
                </div>
                <div className="column is-8 crop-admin-table-title">
                  {MyOfferSwitch()}
                </div>

                <div className="column is-3 has-text-right crop-table-top-pagination">
                  {/* <i className="fa fa-caret-left" aria-hidden="true"></i>{" "} */}
                  {/* <i className="fa fa-caret-right" aria-hidden="true"></i> */}
                </div>
              </div>
              <div className="columns">
                <div className="column">{tabs_display()}</div>
              </div>
              <div className="columns is-flex is-justify-content-space-between">
                <div className="column is-4">
                  <SmartSoftSelect placeHolder="None" options={options} />
                </div>
                <div className="column is-4">
                  <SmartSoftInput
                    inputType="BORDER_LABEL"
                    placeHolder="Zip Code"
                  />
                </div>
              </div>
              <div>
                <SmartSoftTable
                  columns={columns}
                  data={get_table_Data()}
                  tableProps={{ className: "crop-table-layout" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EarnCropsTable;
