import React from "react";
import { SmartSoftButton, SmartSoftForm } from "soft_digi";

//Costomer
const CustomerNotification = ({
  getFormElements,
  formData,
  handleInputChange,
  handleSubmit,
}) => {
  const customer_settings = [
    {
      title: "First Time Registration",
      name: "notification_customer_ftr",
    },
    {
      title: "CROPs Earned",
      name: "notification_customer_ce",
    },
    {
      title: "CROPs Redeemed",
      name: "notification_customer_cr",
    },
    {
      title: "CROPs Balance",
      name: "notification_customer_cbone",
    },
    {
      title: "CROPs Balance",
      name: "notification_customer_cbtwo",
    },
    {
      title: "CROPs Balance",
      name: "notification_customer_cbthree",
    },
    {
      title: "CROPs Balance",
      name: "notification_customer_cbfour",
    },
    {
      title: "CROPs Balance",
      name: "notification_customer_cbfive",
    },
    {
      title: "CROPs Balance",
      name: "notification_customer_cbsix",
    },
    {
      title: "Login Pin Change",
      name: "notification_customer_lpc",
    },
    {
      title: "Mobile Number Change",
      name: "notification_customer_mnc",
    },
    {
      title: "Email ID Change",
      name: "notification_customer_eic",
    },
    {
      title: "Address Change",
      name: "notification_customer_ac",
    },
    {
      title: "Statement Generation",
      name: "notification_customer_sg",
    },
    {
      title: "Account Pre-Suspension",
      name: "notification_customer_aps",
    },
    {
      title: "Account Restoration",
      name: "notification_customer_ar",
    },
  ];

  return (
    <div className="crop-admin-form">
      <SmartSoftForm
        formData={formData}
        setFormData={handleInputChange}
        elements={getFormElements(customer_settings)}
      />
      <div className="has-text-right mt-6">
        <SmartSoftButton
          classList={["smart-crop-admin-action-button", "px-6"]}
          label="Update"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

const CustomerPurchaseNotification = ({
  getFormElements,
  formData,
  handleInputChange,
  handleSubmit,
}) => {
  const customer_settings = [
    {
      title: "Purchase Oders Offers",
      name: "notification_customer_poo",
    },
    {
      title: "PO Pruchase of CROPs",
      name: "notification_customer_poc",
    },
    {
      title: "PO Pruchase of PROPs",
      name: "notification_customer_pop",
    },
    {
      title: "Redemption Order - CROPs or PROPs",
      name: "notification_customer_ro",
    },
    {
      title: "Order confirmed but not paid",
      name: "notification_customer_ocnp",
    },
  ];

  return (
    <div className="crop-admin-form">
      <SmartSoftForm
        formData={formData}
        setFormData={handleInputChange}
        elements={getFormElements(customer_settings)}
      />
      <div className="has-text-right mt-6">
        <SmartSoftButton
          classList={["smart-crop-admin-action-button", "px-6"]}
          label="Update"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

const CustomerRequestNotification = ({
  getFormElements,
  formData,
  handleInputChange,
  handleSubmit,
}) => {
  const customer_settings = [
    {
      title: "Missing CROPs - Claim",
      name: "notification_customer_mcc",
    },
    {
      title: "Missing CROPs - Approved",
      name: "notification_customer_mca",
    },
    {
      title: "Missing CROPs - Rejected",
      name: "notification_customer_mcr",
    },
    {
      title: "Unable to login using CROP number - lodgement ",
      name: "notification_customer_ulcnl",
    },
    {
      title: "Unable to login using CROP number - resolution",
      name: "notification_customer_ulcnr",
    },
    {
      title: "Unable to login using Email ID  - lodgement",
      name: "notification_customer_uleil",
    },
    {
      title: "Unable to login using Email ID   - resolution",
      name: "notification_customer_uleir",
    },
    {
      title: "Promo code not received    - lodgement",
      name: "notification_customer_pcnrl",
    },
    {
      title: "Promo code not received   - resolution",
      name: "notification_customer_pcnrr",
    },
    {
      title: "Unable to redeem   - lodgement",
      name: "notification_customer_url",
    },
    {
      title: "Unable to redeem  - resolution",
      name: "notification_customer_urr",
    },
    {
      title: "Poor Service Experience - lodgement",
      name: "notification_customer_psel",
    },
    {
      title: "Poor Service Experience - resolution",
      name: "notification_customer_pser",
    },
    {
      title: "Poor Product Experience - lodgement",
      name: "notification_customer_ppel",
    },
    {
      title: "Poor Product Experience - resolution",
      name: "notification_customer_pper",
    },
    {
      title: "Order placed but not delivered - lodgement",
      name: "notification_customer_opbndl",
    },
    {
      title: "Order placed but not delivered - resolution",
      name: "notification_customer_opbndr",
    },
    {
      title: "Delay in delivery - lodgement",
      name: "notification_customer_ddl",
    },
    {
      title: "Delay in delivery - resolution",
      name: "notification_customer_ddr",
    },
    {
      title: "Wrong delivery - lodgement",
      name: "notification_customer_wdl",
    },
    {
      title: "Complaint – Wrong delivery- resolution",
      name: "notification_customer_cwdr",
    },
    {
      title: "Complaint–General - lodgement",
      name: "notification_customer_ggl",
    },
    {
      title: "Complaint -  On complaint ",
      name: "notification_customer_coc",
    },
    {
      title: "General -  resolution ",
      name: "notification_customer_gr",
    },
    {
      title: "Offer Rating-   ",
      name: "notification_customer_or",
    },
    {
      title: "Order Return - On submission ",
      name: "notification_customer_oros",
    },
    {
      title: "Processed-Approved - On approval of request ",
      name: "notification_customer_paar",
    },
    {
      title: "Processed–Transaction reversed - On closure of request ",
      name: "notification_customer_ptrcr",
    },
    {
      title: "Processed-Rejected - On rejection of request ",
      name: "notification_customer_prrr",
    },
    {
      title: "Discontinuation of Membership- On submission ",
      name: "notification_customer_dms",
    },
    {
      title: "Request–General- lodgement ",
      name: "notification_customer_rgl",
    },
    {
      title: "Request–General- resolution ",
      name: "notification_customer_rgr",
    },
  ];

  return (
    <div className="crop-admin-form">
      <SmartSoftForm
        formData={formData}
        setFormData={handleInputChange}
        elements={getFormElements(customer_settings)}
      />
      <div className="has-text-right mt-6">
        <SmartSoftButton
          classList={["smart-crop-admin-action-button", "px-6"]}
          label="Update"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

//business
const BusinessNotification = ({
  getFormElements,
  formData,
  handleInputChange,
  handleSubmit,
}) => {
  const customer_settings = [
    {
      title: "First Time Registration",
      name: "notification_Business_ftr",
    },
    {
      title: "CROPs Offered",
      name: "notification_Business_co",
    },
    {
      title: "CROPs Redeemed",
      name: "notification_Business_cr",
    },
    {
      title: "Offer Upload Confirmation",
      name: "notification_Business_ouc",
    },
    {
      title: "Offer Removal Confirmation",
      name: "notification_Business_orc",
    },
    {
      title: "Offer Value amendment",
      name: "notification_Business_ova",
    },
    {
      title: "Low Redemption Rate",
      name: "notification_Business_lrr",
    },
    {
      title: "High Redemption Rate",
      name: "notification_Business_hrr",
    },
    {
      title: "Login Pin Change",
      name: "notification_Business_lpc",
    },
    {
      title: "Mobile Number Change",
      name: "notification_Business_mnc",
    },
    {
      title: "Email ID Change",
      name: "notification_Business_eic",
    },
    {
      title: "Address Change",
      name: "notification_Business_ac",
    },
    {
      title: "Statement Generation",
      name: "notification_Business_sg",
    },
    {
      title: "Account Pre-Suspension",
      name: "notification_Business_aps",
    },
    {
      title: "Account Restoration",
      name: "notification_Business_ar",
    },
  ];

  return (
    <div className="crop-admin-form">
      <SmartSoftForm
        formData={formData}
        setFormData={handleInputChange}
        elements={getFormElements(customer_settings)}
      />
      <div className="has-text-right mt-6">
        <SmartSoftButton
          classList={["smart-crop-admin-action-button", "px-6"]}
          label="Update"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};
const BusinessPaymentNotification = ({
  getFormElements,
  formData,
  handleInputChange,
  handleSubmit,
}) => {
  const customer_settings = [
    {
      title: "Customer Payment - On Payment",
      name: "notification_Business_cpop",
    },
    {
      title: "Customer Redemption-CROPs or PROPs debited",
      name: "notification_Business_crcpd",
    },
    {
      title: "Bid Submission Acknowledgement–Top Ranking - Submission",
      name: "notification_Business_bsatrbs",
    },
    {
      title: "Bid Submission Acknowledgement–On Bid Submission",
      name: "notification_Business_bsabs",
    },
    {
      title: "Bid Payment Request–Top Ranking - On Closure of Auction",
      name: "notification_Business_bprtrca",
    },
    {
      title: "Bid Payment Request – On Closure of Auction",
      name: "notification_Business_bprca",
    },
    {
      title: "Bid Payment Acknowledgement–Top Ranking – On Payment",
      name: "notification_Business_bpatrp",
    },
    {
      title: "Bid Payment Acknowledgement – Promo – On Payment",
      name: "notification_Business_bpapp",
    },
    {
      title: "Bid Payment Reminder – Top Ranking  – Bid Payment Request",
      name: "notification_Business_bprtrbpr",
    },
    {
      title: "Bid Payment Reminder – Promo – Bid Payment Request",
      name: "notification_Business_bprpbpr",
    },
    {
      title: "Bid Cancellation – Top Ranking – Bid Payment Reminder",
      name: "notification_Business_bctrbpr",
    },
    {
      title: "Bid Cancellation – Promo –  Bid Payment Reminder",
      name: "notification_Business_bcpbpr",
    },
    {
      title: "Bid Unsuccessful – Top Ranking –  One day prior to target date",
      name: "notification_Business_butrodptd",
    },
    {
      title: "Bid Unsuccessful – Promo –  One day prior to target date",
      name: "notification_Business_bupodptd",
    },
    {
      title: "Daily Sales Report –  Next Day",
      name: "notification_Business_dsrn",
    },
  ];

  return (
    <div className="crop-admin-form">
      <SmartSoftForm
        formData={formData}
        setFormData={handleInputChange}
        elements={getFormElements(customer_settings)}
      />
      <div className="has-text-right mt-6">
        <SmartSoftButton
          classList={["smart-crop-admin-action-button", "px-6"]}
          label="Update"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

const BusinessRequestNotification = ({
  getFormElements,
  formData,
  handleInputChange,
  handleSubmit,
}) => {
  const customer_settings = [
    {
      title: "Request - Missing CROPs - Processing -On customer submission",
      name: "notification_Business_rmcpcs",
    },
    {
      title: " Unable to login using CROP number -lodgement ",
      name: "notification_Business_ulucnl",
    },
    {
      title: "Unable to login using CROP number  - resolution",
      name: "notification_Business_ulucnr",
    },
    {
      title: "Unable to login using mobile number - lodgement ",
      name: "notification_Business_ulumnl",
    },
    {
      title: "Unable to login using mobile number  - resolution",
      name: "notification_Business_ulumnr",
    },
    {
      title: "Unable to login using Email ID   - lodgement",
      name: "notification_Business_ulueil",
    },
    {
      title: "Unable to login using Email ID    - resolution",
      name: "notification_Business_ulueir",
    },
    {
      title: "Unable to upload offer    - lodgement",
      name: "notification_Business_uuol",
    },
    {
      title: "Unable to upload offer  - resolution",
      name: "notification_Business_uuor",
    },
    {
      title: "Unable to bid for Top Ranking   - lodgement",
      name: "notification_Business_ubtrl",
    },
    {
      title: "Unable to bid for Top Ranking - resolution",
      name: "notification_Business_ubtrr",
    },
    {
      title: "Unable to bid for Promo - lodgement",
      name: "notification_Business_ubpl",
    },
    {
      title: "Unable to bid for Promo - resolution",
      name: "notification_Business_ubfpr",
    },
    {
      title: "Unable to pay for bid  - lodgement",
      name: "notification_Business_upbl",
    },
    {
      title: "Unable to pay for bid - resolution",
      name: "notification_Business_upfbr",
    },
    {
      title: "Promo not applied despite payment  - lodgement",
      name: "notification_Business_pnadpl",
    },
    {
      title: "Promo not applied despite payment - resolution",
      name: "notification_Business_pnaddpr",
    },
    {
      title: "Payment not received - lodgement",
      name: "notification_Business_pnr",
    },
    {
      title: "Payment not received- resolution",
      name: "notification_Business_pnrr",
    },
    {
      title: "Bid Cancellation Fee  - lodgement",
      name: "notification_Business_bcfl",
    },
    {
      title: "Bid Cancellation Fee- resolution",
      name: "notification_Business_bcfr",
    },
    {
      title: "Complaint–General - lodgement",
      name: "notification_Business_cgl",
    },
    {
      title: "General -  resolution ",
      name: "notification_Business_gr",
    },
    {
      title: "Order Return - Processing -  submission ",
      name: "notification_Business_orps",
    },
    {
      title: "Request – General- lodgement ",
      name: "notification_Business_rgl",
    },
    {
      title: "Request – General- resolution ",
      name: "notification_Business_rgr",
    },
  ];

  return (
    <div className="crop-admin-form">
      <SmartSoftForm
        formData={formData}
        setFormData={handleInputChange}
        elements={getFormElements(customer_settings)}
      />
      <div className="has-text-right mt-6">
        <SmartSoftButton
          classList={["smart-crop-admin-action-button", "px-6"]}
          label="Update"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

// export default CustomerNotification;
export {
  CustomerNotification,
  CustomerPurchaseNotification,
  CustomerRequestNotification,
  BusinessNotification,
  BusinessPaymentNotification,
  BusinessRequestNotification,
};
