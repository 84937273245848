import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SmartSoftTable } from "soft_digi";
import { SmartSoftCheckRadioSwitch } from "soft_digi";
import ManagementRequestForm from "./ManagementRequestForm";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { SERVICES_REQUEST_URLS } from "../../../../api/Services/ServiceUrls";
import { showNotification } from "../../../../services/notifyService";
import { get, post } from "../../../../services/smartApiService";

const ManagementRequest = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState("1");
  const [tabData, setTabData] = useState([]);
  const { setLoading, openModal, closeModal } = useSiteContext();

  const baseNav = "/operations";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const MyOfferSwitch = () => {
    const [formData, setFormData] = useState({});
    const [formSubmit, setFormSubmit] = useState(false);
    const [formErrors, setFormErrors] = useState(false);
    const Customer_request = [{ value: "1", label: "Customer Request" }];
    const Business_request = [{ value: "2", label: "Business Request" }];

    const handleInputChange = (name, value) => {
      setFormData((prev) => ({ ...prev, [name]: value }));
    };
    return (
      <>
        <div className="is-flex">
          <div className="my-offer-month-switch mr-5">
            <SmartSoftCheckRadioSwitch
              options={Customer_request}
              switchMode={true}
              isRight={true}
              name="switchone"
              value={category}
              onChange={(value) => setCategory("1")}
            />
          </div>
          <div className="my-offer-year-switch">
            <SmartSoftCheckRadioSwitch
              options={Business_request}
              switchMode={true}
              isRight={true}
              name="switch"
              value={category}
              onChange={(value) => setCategory("2")}
            />
          </div>
        </div>
      </>
    );
  };

  const loadTableData = () => {
    setLoading(true, "Logging in Please Wait....");
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setTabData([]);
      setLoading(false);
    };
    let url = SERVICES_REQUEST_URLS.GET_ALL_ADMIN;
    let post_data = {
      request_mode: category == 1 ? "CUSTOMER" : "BUSINESS",
    };
    const subscription = post(url, post_data, handleError).subscribe(
      (response) => {
        setTabData(response.data);
        console.table("testing", response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };
  useEffect(() => {
    loadTableData();
  }, [category]);

  const ManagementCustomerRequest = () => {
    const { setLoading, openModal, closeModal } = useSiteContext();

    const viewEditForm = (id, action_name) => {
      setLoading(true, "Please Wait....");
      const handleError = (errorMessage) => {
        showNotification("error", errorMessage);
        setLoading(false);
      };
      const subscription = post(
        SERVICES_REQUEST_URLS.GET_ONE,
        { id: id },
        handleError
      ).subscribe((response) => {
        console.log("response ", response);
        openNewForm(response.data);
        setLoading(false);
      });
      return () => {
        subscription.unsubscribe();
      };
    };
    const openNewForm = (data = {}) => {
      let modalObject = {
        body: (
          <ManagementRequestForm loadTableData={loadTableData} dataIn={data} />
        ),
        modalClass: "crop-admin-modal smart-modal-50",
        bodyClose: false,
      };
      openModal(modalObject);
    };
    const buttons = [
      {
        label: "Yes",
        leftIcon: "",
        type: "",
        classList: ["button is-rounded is-link"],
        onClick: (data) => {
          console.log(data);
          // viewEditForm(data["ID"]);
        },
      },
      {
        label: "No",
        leftIcon: "",
        type: "",
        classList: ["button is-rounded"],
      },
    ];

    const nameFunction = (row) => {
      return (
        <>
          <div
            className="is-flex is-clickable"
            onClick={() => viewEditForm(row["ID"])}
          >
            <figure>{row["request_id"] || ""}</figure>
          </div>
        </>
      );
    };

    const columns = [
      {
        title: "NUMBER",
        index: "request_id",
        valueFunction: nameFunction,
      },
      {
        title: "RAISED",
        index: "request_date",
        type: "date",
        dateFormat: "DD/MM/YYYY",
      },
      { title: "TYPE", index: "request_type" },
      { title: "DETAILS", index: "request_description" },
      { title: "PCM", index: "request_pcm" },
      {
        title: "ACTIONED",
        index: "actioned",
        type: "date",
        dateFormat: "DD/MM/YYYY",
      },
      { title: "STATUS", index: "request_status" },
      { title: "RESOLUTION", index: "request_resolution" },
      // { title: "SLA", index: "sla", type: "buttons", buttons: buttons },
    ];

    const CustomerTableData = [
      {
        number: "R-8070707",
        raised: "2023/09/26",
        type: "Appliction Is Very Slow",
        details: "Performance",
        pcm: "App",
        actioned: "2023/09/26",
        status: "completed",
        resolution: "Thankyou",
      },
    ];

    const BusinessTableData = [
      {
        number: "R-8070707",
        raised: "2023/09/26",
        type: "Appliction Is Very Slow",
        details: "Performance",
        pcm: "App",
        actioned: "2023/09/26",
        status: "completed",
        resolution: "Thank you",
      },
      {
        number: "R-8070707",
        raised: "2023/09/26",
        type: "Appliction Is Very Slow",
        details: "Performance",
        pcm: "9807654321",
        actioned: "2023/11/05",
        status: "completed",
        resolution: "Thankyou",
      },
    ];
    return (
      <>
        <div className="smart-crop-admin-container">
          <div className="columns">
            <div className="column">
              <div className="card">
                <div className="columns is-vcentered">
                  <div className="column is-1 crop-admin-table-back">
                    <span className="icon" onClick={() => navigateLink("home")}>
                      <i className="fa fa-undo" aria-hidden="true"></i>
                    </span>
                  </div>
                  <div className="column is-8 crop-admin-table-title">
                    {MyOfferSwitch()}
                  </div>

                  <div className="column is-3 has-text-right crop-table-top-pagination">
                    {/* <i className="fa fa-caret-left" aria-hidden="true"></i>{" "} */}
                    {/* <i className="fa fa-caret-right" aria-hidden="true"></i> */}
                  </div>
                </div>
                <SmartSoftTable
                  columns={columns}
                  data={tabData}
                  tableProps={{ className: "crop-table-layout" }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return <>{ManagementCustomerRequest()}</>;
};

export default ManagementRequest;
