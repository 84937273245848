import React, { useState } from "react";
import { SmartSoftDate } from "soft_digi";
import { ADMIN_CHART } from "../../../services/ImageService";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";

const RevenueOverChart = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    // Title,
    Tooltip
    // Legend
  );

  const data = {
    labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    datasets: [
      {
        label: "Offers - Sold",
        data: [65, 59, 80, 81, 56, 55, 40],
        backgroundColor: "rgba(0,83,223)",
        borderColor: "rgba(0,83,223)",
        borderWidth: 1,
      },
      {
        label: "Offers - Redeemed",
        data: [65, 59, 80, 81, 56, 55, 40],
        backgroundColor: "rgba(88,209,252)",
        borderColor: "rgba(88,209,252)",
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        // text:'Bar chart with Data and Options'
      },
      filler: {
        propagate: true,
      },
    },
  };
  return (
    <>
      <div className="">
        <Bar data={data} options={options} />
      </div>
    </>
  );
};

const RevenueOverview = () => {
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const SalesRevenue = () => {
    return (
      <>
        <div key={"RevenueOverviewFirstcard"}  className="card mt-4 sales-table-layout">
          <table className="table is-fullwidth">
            <tr>
              <th key={"RevenueOverviewFirstColSpan"} colSpan="4">Sales Revenue</th>
            </tr>
            <tr>
              <th>Sector</th>
              <td>Amount</td>
              <td>Percentage</td>
              <td>Last Update</td>
            </tr>
            <tr>
              <th>Dine</th>
              <td>1250</td>
              <td>26%</td>
              <td>20/09/2023</td>
            </tr>
            <tr>
              <th>Shop</th>
              <td>1000</td>
              <td>11%</td>
              <td>21/09/2023</td>
            </tr>
            <tr>
              <th>Retail</th>
              <td>900</td>
              <td>19%</td>
              <td>22/09/2023</td>
            </tr>
            <tr>
              <th>Services</th>
              <td>500</td>
              <td>11%</td>
              <td>21/12/2023</td>
            </tr>
            <tr>
              <th>Others</th>
              <td>1150</td>
              <td>23%</td>
              <td>21/09/2023</td>
            </tr>
            <tr>
              <th key={"RevenueOverviewSecondColSpan"} colSpan="4">Services Revenue</th>
            </tr>
            <tr>
              <th>Category</th>
              <td>Amount</td>
              <td>Percentage</td>
              <td>Last Update</td>
            </tr>
            <tr>
              <th>Top Ranking</th>
              <td>2000</td>
              <td>66%</td>
              <td>22/09/23</td>
            </tr>
            <tr>
              <th>Promos</th>
              <td>1000</td>
              <td>33%</td>
              <td>22/09/23</td>
            </tr>
          </table>
        </div>
      </>
    );
  };

  return (
    <>
      <div  key={"RevenueOverviewSecondcard"} className="smart-crop-dashboard-container card">
        <div key={"RevenueOverviewFirstColumns"} className="columns p-3 m-0">
          <div key={"RevenueOverviewFirstColumn"} className="column is-6 crop-admin-form ">
            <SmartSoftDate
              label="From "
              placeHolder="DD/MM/YY"
              value={formData?.from_date || ""}
              onChange={(value) => handleInputChange("from_date", value)}
              inputType="BORDER_LABEL"
              inputProps={{ isFocussed: true }}
            />
          </div>
          <div key={"RevenueOverviewSecondColumn"} className="column is-6 crop-admin-form ">
            <SmartSoftDate
              label="To "
              placeHolder="DD/MM/YY"
              value={formData?.to_date || ""}
              onChange={(value) => handleInputChange("to_date", value)}
              inputType="BORDER_LABEL"
              inputProps={{ isFocussed: true }}
            />
          </div>
        </div>
        <div className=" has-text-weight-bold ml-3">Revenue Overview</div>
        <div className="">{RevenueOverChart()}</div>
      </div>
      <div>{SalesRevenue()}</div>
    </>
  );
};

export default RevenueOverview;
