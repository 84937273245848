import React, { useState } from "react";
import { SmartSoftTable } from "soft_digi";
import { useNavigate } from "react-router-dom";
import { useSiteContext } from "../../../contexts/SiteProvider";

const CropsTable = () => {
  const [tabData, setTabData] = useState([]);
  const { setLoading, openModal, closeModal } = useSiteContext();
  const navigate = useNavigate();

  const baseNav = "/database";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const columns = [
    { title: "DATE", index: "dateline" },
    { title: "ORDER NUMBER", index: "orderline" },
    { title: "AMOUNT", index: "amountline" },
    //{ title: "DESCRIPTION", index: "descriptionline" },
    { title: "DEBIT", index: "debitline" },
    { title: "CREDIT", index: "creditline" },
  ];
  const data = [
    {
      dateline: "22/05/2023",
      orderline: "pLgvbkjljjvgjghhghg",
      amountline: "75",
      descriptionline: "Purchased CROP",
      debitline: "",
      creditline: "50.00",
    },
    {
      dateline: "22/05/2023",
      orderline: "pLgvbkjljjvgjghhghg",
      amountline: "10",
      descriptionline: "Purchased CROP",
      debitline: "",
      creditline: "100.00",
    },
    {
      dateline: "22/05/2023",
      orderline: "pLgvbkjljjvgjghhghg",
      amountline: "10",
      descriptionline: "Purchased CROP",
      debitline: "",
      creditline: "100.00",
    },
    {
      dateline: "22/05/2023",
      orderline: "pLgvbkjljjvgjghhghg",
      amountline: "10",
      descriptionline: "Purchased CROP",
      debitline: "",
      creditline: "100.00",
    },
    {
      dateline: "22/05/2023",
      orderline: "pLgvbkjljjvgjghhghg",
      amountline: "10",
      descriptionline: "Purchased CROP",
      debitline: "",
      creditline: "100.00",
    },
    {
      dateline: "22/05/2023",
      orderline: "pLgvbkjljjvgjghhghg",
      amountline: "10",
      descriptionline: "Purchased CROP",
      debitline: "",
      creditline: "100.00",
    },
  ];

  return (
    <div className="smart-crop-admin-container">
      <div key={"CropsTablecolumnsone"} className="columns">
        <div key={"CropsTablecolumnone"} className="column">
          <div key={"CropsTablecardone"} className="card">
            <div key={"CropsTablecolumnstwo"} className="columns is-vcentered">
              <div
                key={"CropsTablecolumntwo"}
                className="column is-1 crop-admin-table-back"
              >
                <span
                  className="icon"
                  onClick={() => navigateLink("database_management-list")}
                >
                  <i className="fa fa-undo" aria-hidden="true"></i>
                </span>
              </div>
              <div
                key={"CropsTablecolumnthree"}
                className="column is-8 crop-admin-table-title"
              >
                Customer CROPs Details
              </div>
              <div
                key={"CropsTablecolumnfour"}
                className="column is-3 has-text-right crop-table-top-pagination"
              >
                {/* <i className="fa fa-caret-left" aria-hidden="true"></i>{" "}
                  <i className="fa fa-caret-right" aria-hidden="true"></i> */}
              </div>
            </div>
            <SmartSoftTable
              columns={columns}
              data={data}
              tableProps={{ className: "crop-table-layout" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CropsTable;
