import { useNavigate } from "react-router-dom";
import { SmartSoftTable } from "soft_digi";
import UpdatePopup from "./UpdatePopup";
import { useSiteContext } from "../../../../contexts/SiteProvider";

const PaymentTable = () => {
  const navigate = useNavigate();
  const basenav = "/operations";

  const navigateLink = (index) => {
    navigate(basenav + "/" + index);
  };

  const { setLoading, openModal, closeModal } = useSiteContext();
  const openNewForm = (data = {}) => {
    // console.log(data);
    let modalObject = {
      body: <UpdatePopup loadTableData dataIn />,
      modalClass: "crop-admin-modal smart-modal-50",
      bodyClose: false,
    };
    openModal(modalObject);
  };

  const viewButtons = [
    {
      label: "View",
      leftIcon: "",
      type: "",
      classList: [
        "button admin-crop-presuspended-status is-rounded is-small m-0",
      ],
      onClick: (data) => {
        // console.log("data", data);
        navigateLink("payment-history-list");
      },
      // onclick: ()=>{navigateLink("payment-history-list")},
    },
  ];

  const updateButton = [
    {
      label: "Update",
      leftIcon: "",
      type: "",
      classList: [
        "button admin-crop-presuspended-Update-Update is-rounded is-small m-0",
      ],
      onClick: () => {
        openNewForm();
      },
    },
  ];

  const columns = [
    { title: "BUSINESS NAME", index: "businessname", width: "33" },
    { title: "AMOUNT", index: "amount", width: "33" },
    {
      title: "VIEW",
      index: "view",
      type: "buttons",
      width: "34",
      buttons: viewButtons,
    },
    {
      title: "ACTION",
      index: "update",
      type: "buttons",
      width: "33",
      buttons: updateButton,
    },
  ];

  const data = [
    { businessname: "Arrium", amount: "AUD 0.00" },
    { businessname: "Billabong", amount: "AUD 0.00" },
  ];

  return (
    <>
      <div className="smart-crop-admin-container">
        <div key={"PaymentTablecolumnsone"} className="columns">
          <div key={"PaymentTablecolumnone"} className="column ">
            <div key={"PaymentTablecardone"} className="card">
              <div
                key={"PaymentTablecolumnstwo"}
                className="columns is-vcentered"
              >
                <div
                  key={"PaymentTablecolumntwo"}
                  className="column is-1 crop-admin-table-back"
                >
                  <span className="icon" onClick={() => navigateLink("home")}>
                    <i className="fa fa-undo" aria-hidden="true"></i>
                  </span>
                </div>
                <div
                  key={"PaymentTablecolumnthree"}
                  className="column is-8 crop-admin-table-title"
                >
                  Business Data
                </div>

                <div
                  key={"PaymentTablecolumnfour"}
                  className="column is-3 has-text-right crop-table-top-pagination"
                >
                  {/* <i className="fa fa-caret-left" aria-hidden="true"></i>{" "} */}
                  {/* <i className="fa fa-caret-right" aria-hidden="true"></i> */}
                </div>
              </div>
              <SmartSoftTable
                columns={columns}
                data={data}
                tableProps={{ className: "crop-table-layout" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentTable;
