import React, { useState } from "react";
import {SmartSoftInput} from "soft_digi";


const SmartPassWord = ({name,handleInputChange,value,passwordValidator,...rest}) => {
    const [type, setType] = useState(true);
    const [strength, setStrength] = useState(0);

    const calculateStrength = (password) => {
        let score = 0;
        if (!password) {
            setStrength(0);
            return;
        }
       //console.log("score = " , score);
        // Add points for length
        //score += password.length;
        if(password.length > 3){
            score += 1;
        }
        if(password.length > 6){
            score += 1;
        }
        // Add points for uppercase letters
        if (/[A-Z]/.test(password)) {
            score += 1;
        }
        // Add points for lowercase letters
        if (/[a-z]/.test(password)) {
            score += 1;
        }
        // Add points for numbers
        if (/\d/.test(password)) {
            score += 1;
        }
        // Add points for special characters
        if (/[^A-Za-z0-9]/.test(password)) {
            score += 1;
        }
        //console.log("score = " , score);
        setStrength(score);
    };

    const handleChange=(value)=>{ 
        if(passwordValidator){
            calculateStrength(value);   
        }        
        handleInputChange(name, value)
    }
    const password_show=()=>{
        setType(!type);
    }
    const rightIcon=()=>{
        return <span className="icon is-small is-right smart-password-icon" onClick={password_show}>
            <i className={"fa " + (type ? "fa-eye-slash" : "fa-eye")}></i></span>;
    }

    const display_text=()=>{
        return value && value.length >0 &&  <span 
              className={`progress-text ${strength < 4 ? 'has-text-danger' : strength < 6 ? 'has-text-warning' : 'has-text-success'}`}
             >
            {strength < 4 ? 'week' : strength < 6 ? "average" : "strong"}
           </span>
    }

    return (
        <div className="mb-3 smart-password-field">
            <SmartSoftInput key="text-4"            
            value={value||""}
            onChange={(value) => handleChange(value)}            
            rightIcon=""
            type={type ? "password":"text"}
            rightIconFunction={rightIcon}
            {...rest}                             
          />  
          {passwordValidator && display_text()}  
          {passwordValidator && 
          <progress
            className={`progress ${strength < 4 ? 'is-danger' : strength < 6 ? 'is-warning' : 'is-success'}`}
            value={strength}
            max="6"
        >
            {strength}0%
        </progress>
          }
        </div>
    )
}

export default SmartPassWord;
