import React from 'react'

import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import { ActiveOffersTable, CropsTable, CustomerAuditTrailTable, CustomerDataBusinessData, LikesTable, RateProductTable, RatingsTable, UserManagementTable, UserProfile,PropsTable, } from '../../pages/Admin';

import InvoiceTable from '../../pages/Admin/Database/InvoiceTable';






const DatabaseRoute = () => {
  return (
    <Routes>
      <Route path="/user-management" element={<UserManagementTable />} />
      <Route path="/ratings-list/:id" element={<RatingsTable />} />
      <Route path='/customer-audit-trail-list/:id' element={<CustomerAuditTrailTable />} />
      <Route path='/likes-list/:id' element={<LikesTable />} />
      <Route path='/database_management-list' element={<CustomerDataBusinessData />} />
      <Route path='/userprofile' element={<UserProfile />} />
      <Route path='/rate-product-list/:id' element={<RateProductTable />} />
      <Route path='/active-offers-table' element={<ActiveOffersTable />} />
      <Route path='/invoice-table/:id' element={<InvoiceTable />} />
      <Route path='/crops-table' element={<CropsTable />} />
      <Route path='/props-table'element={<PropsTable />} />
    </Routes>
  )
}

export default DatabaseRoute