import React from "react";

const Balance = () => {
  return (
    <div key={"BalanceFirstColumns"} className="columns p-3 is-multiline card smart-crop-dashboard-container">
      <div key={"BalanceFirstColumn"} className="column is-12 dashboard-user-name p-0 m-0">Balance</div>
      <div key={"BalanceSecondColumn"} className="column is-12">
        <div key={"BalanceSecondColumns"} className="columns is-multiline">
          <div key={"BalanceThirdColumn"} className="column is-6">
            <div className="dashboard-crop-balance-card">
              <div className=" ml-2 has-text-weight-bold">
                Total CROPs Credit
              </div>
              <div className="is-flex">
                <div className="dashboard-user-name ml-2 is-size-4 mt-1">
                  <i className="fa fa-product-hunt" aria-hidden="true"></i>
                  <img className="Balance-image-Cimage" src="" alt="" />
                </div>
                <div className="admin-crop-card-number ml-4 mt-2">
                  176756.86
                </div>
              </div>
            </div>
          </div>
          <div key={"BalanceFourthColumn"} className="column is-6">
            <div className="dashboard-crop-balance-card">
              <div className=" ml-2 has-text-weight-bold">
                Total CROPs Debit
              </div>
              <div className="is-flex">
                <div className="dashboard-user-name ml-2 is-size-4 mt-1">
                  <i className="fa fa-product-hunt" aria-hidden="true"></i>
                </div>
                <div className="admin-crop-card-number-debit ml-4 mt-2">
                  176756.86
                </div>
              </div>
            </div>
          </div>
          <div key={"BalanceFifthColumn"} className="column is-6">
            {/* <div className="dashboard-crop-balance-card">
              <div className=" ml-2 has-text-weight-bold">
                Total PROPs Credit
              </div>
              <div className="is-flex">
                <div className="dashboard-user-name ml-2 is-size-4 mt-1">
                  <i class="fa fa-product-hunt" aria-hidden="true"></i>
                </div>
                <div className="admin-crop-card-number ml-4 mt-2">
                  176756.86
                </div>
              </div>
            </div> */}
          </div>
          <div key={"BalanceSixthColumn"} className="column is-6">
            {/* <div className="dashboard-crop-balance-card">
              <div className=" ml-2 has-text-weight-bold">
                Total PROPs Debit
              </div>
              <div className="is-flex">
                <div className="dashboard-user-name ml-2 is-size-4 mt-1">
                  <i class="fa fa-product-hunt" aria-hidden="true"></i>
                </div>
                <div className="admin-crop-card-number-debit ml-4 mt-2">
                  176756.86
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Balance;
