import React, { useState, useEffect } from "react";
import { SmartSoftTable } from "soft_digi";
import { useNavigate, useParams } from "react-router-dom";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { decrypt_data } from "../../../services/sessionService";
import { DATABASE_URLS, USER_URLS } from "../../../api/Admin/AurhUrls";
import { post } from "../../../services/smartApiService";
import { showAlertAutoClose } from "../../../services/notifyService";

const CustomerAuditTrailTable = () => {
  const { id } = useParams();
  const decodeId = decodeURIComponent(id);
  const decrypted_id = decrypt_data(decodeId);
  const [tabData, setTabData] = useState([]);
  const { setLoading, openModal, closeModal } = useSiteContext();
  const navigate = useNavigate();

  const baseNav = "/database";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const loadTableData = () => {
    //setLoading(true, "Logging in Please Wait....");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    let post_data = { sd_mt_userdb_id: decrypted_id };
    let url = DATABASE_URLS.GET_ALL_AUDIT;
    const subscription = post(url, post_data, handleError).subscribe(
      (response) => {
        setTabData(response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);
  const columns = [
    { title: "", index: "", width: "10" },
    { title: "DATE", index: "date", width: "30" },
    { title: "DESCRIPTION", index: "message" },
  ];
  const data = [
    { sectorline: "31/07/2023", descriptionline: "Tier changed successfully" },
    { sectorline: "31/07/2023", descriptionline: "Tier changed successfully" },
    { sectorline: "31/07/2023", descriptionline: "Tier changed successfully" },
    { sectorline: "31/07/2023", descriptionline: "Tier changed successfully" },
    { sectorline: "31/07/2023", descriptionline: "Tier changed successfully" },
    { sectorline: "31/07/2023", descriptionline: "Tier changed successfully" },
  ];
  return (
    <>
      <div className="smart-crop-admin-container">
        <div key={"CustomerAuditTrailTablecolumnsone"} className="columns">
          <div key={"CustomerAuditTrailTablecolumnone"} className="column">
            <div key={"CustomerAuditTrailTablecardone"} className="card">
              <div
                key={"CustomerAuditTrailTablecolumnstwo"}
                className="columns is-vcentered"
              >
                <div
                  key={"CustomerAuditTrailTablecolumntwo"}
                  className="column is-1 crop-admin-table-back"
                >
                  <span
                    className="icon"
                    onClick={() => navigateLink("database_management-list")}
                  >
                    <i className="fa fa-undo" aria-hidden="true"></i>
                  </span>
                </div>
                <div
                  key={"CustomerAuditTrailTablecolumnthree"}
                  className="column is-8 crop-admin-table-title"
                >
                  Customer Audit Trail
                </div>
                <div
                  key={"CustomerAuditTrailTablecolumnfour"}
                  className="column is-3 has-text-right crop-table-top-pagination"
                >
                  {/* <i className="fa fa-caret-left" aria-hidden="true"></i>{" "}
                                        <i className="fa fa-caret-right" aria-hidden="true"></i> */}
                </div>
              </div>
              <SmartSoftTable
                columns={columns}
                data={tabData}
                tableProps={{ className: "crop-table-layout" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerAuditTrailTable;
