import React from 'react'

const Sales = () => {
  return (
    <div className='smart-crop-dashboard-container card p-4'>
        <div className='has-text-weight-bold is-size-5'>
            Total Sales
        </div>
        <div className='is-flex'>
            <div className='total-sales-amount'>$2529.00</div>
            <div className='is-flex sales-amount-percentage mt-2 ml-2'>
            <i className="fa fa-caret-up" aria-hidden="true"></i>
            <div className='change-percentage ml-2'>105%</div>

            </div>
        </div>
        <div className='sales-person-details '>
            <div className=''>
                <div className="is-size-6 has-text-weight-bold">Woolworths</div>
                <div className="sales-product-title">Title,Title</div>
            </div>
            <div className=''>
            $1913.00
            </div>

        </div>
        <div className='sales-person-details '>
            <div className=''>
                <div className="is-size-6 has-text-weight-bold">Woolworths</div>
                <div className="sales-product-title">Title,Title</div>
            </div>
            <div className=''>
            $1913.00
            </div>

        </div>
        <div className='sales-person-details '>
            <div className=''>
                <div className="is-size-6 has-text-weight-bold">Woolworths</div>
                <div className="sales-product-title">Title,Title</div>
            </div>
            <div className=''>
            $1913.00
            </div>

        </div>
    </div>
  )
}

export default Sales