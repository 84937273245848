import React, { useState } from "react";
import { SmartSoftTable } from "soft_digi";
import { useNavigate } from "react-router-dom";
import { useSiteContext } from "../../../contexts/SiteProvider";

const CropDetails = () => {
  const [tabData, setTabData] = useState([]);
  const { setLoading, openModal, closeModal } = useSiteContext();
  const navigate = useNavigate();

  const baseNav = "/account-management";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const columns = [
    { title: "DATE", index: "dateline" },
    { title: "ORDER NUMBER", index: "orderline" },
    { title: "AMOUNT", index: "amountline" },
    { title: "DESCRIPTION", index: "descriptionline" },
    { title: "DEBIT", index: "debitline" },
    { title: "CREDIT", index: "creditline" },
  ];

  const data = [
    {
      dateline: "22/05/2023",
      orderline: "pi-3narygugijgcgghjiugtxztfgij",
      amountline: "1000",
      descriptionline: "Purchased PROP",
      debitline: "",
      creditline: "1000.00",
    },
    {
      dateline: "19/07/2023",
      orderline: "pi-3narygugijgcgghjiugtxztfgij",
      amountline: "1000",
      descriptionline: "Purchased PROP",
      debitline: "",
      creditline: "1000.00",
    },
    {
      dateline: "05/07/2023",
      orderline: "pi-3narygugijgcgghjiugtxztfgij",
      amountline: "1000",
      descriptionline: "Purchased PROP",
      debitline: "",
      creditline: "1000.00",
    },
    {
      dateline: "04/07/2023",
      orderline: "pi-3narygugijgcgghjiugtxztfgij",
      amountline: "1000",
      descriptionline: "Purchased PROP",
      debitline: "",
      creditline: "1000.00",
    },
    {
      dateline: "04/07/2023",
      orderline: "pi-3narygugijgcgghjiugtxztfgij",
      amountline: "1000",
      descriptionline: "Purchased PROP",
      debitline: "",
      creditline: "1000.00",
    },
    {
      dateline: "03/07/2023",
      orderline: "pi-3narygugijgcgghjiugtxztfgij",
      amountline: "1000",
      descriptionline: "Purchased PROP",
      debitline: "",
      creditline: "1000.00",
    },
  ];

  //Customer CROPs Details
  const columns_CROPs = [
    { title: "DATE", index: "dateline" },
    { title: "ORDER NUMBER", index: "orderline" },
    { title: "AMOUNT", index: "amountline" },
    { title: "DESCRIPTION", index: "descriptionline" },
    { title: "DEBIT", index: "debitline" },
    { title: "CREDIT", index: "creditline" },
  ];

  const data_CROPs = [
    {
      dateline: "22/05/2023",
      orderline: "pi-3narygugijgcgghjiugtxztfgij",
      amountline: "75",
      descriptionline: "Purchased PROP",
      debitline: "",
      creditline: "1000.00",
    },
    {
      dateline: "22/05/2023",
      orderline: "pi-3narygugijgcgghjiugtxztfgij",
      amountline: "10",
      descriptionline: "Purchased PROP",
      debitline: "",
      creditline: "1000.00",
    },
  ];

  return (
    <div className="InvoiceDetails">
      <div key={"CropDetailsFirstcolumns"} className="columns">
        <div key={"CropDetailsFirstcolumn"} className="column">
          <div  className="card">
            <div key={"CropDetailsSecondcolumns"} className="columns is-vcentered">
              <div key={"CropDetailsSecondcolumn"} className="column is-1 crop-admin-table-back">
                <span
                  className="icon"
                  onClick={() => navigateLink("transaction-list")}
                >
                  <i className="fa fa-undo" aria-hidden="true"></i>
                </span>
              </div>
              <div key={"CropDetailsThirdcolumn"} className="column is-8 crop-admin-table-title">
                Customer CROPs Details
              </div>

              <div key={"CropDetailsFourthcolumn"} className="column is-3 has-text-right crop-table-top-pagination">
                {/* <i className="fa fa-caret-left" aria-hidden="true"></i>{" "} */}
                {/* <i className="fa fa-caret-right" aria-hidden="true"></i> */}
              </div>
            </div>
            <SmartSoftTable
              columns={columns}
              data={data}
              tableProps={{ className: "crop-table-layout" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CropDetails;
