import React from 'react'
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import CropDetails from '../../pages/Admin/AccountManagement/CropDetails';
import { InvoiceDetails, TransactionDetails } from '../../pages/Admin';
import BusinessCROPsDetails from './../../pages/Admin/AccountManagement/BusinessCROPsDetails';



const AccountManagementRoute = () => { 
  return (
    <Routes>
    <Route path="/crop-details" element={<CropDetails />} />    
    <Route path="/transaction-list" element={<TransactionDetails />} />   
    <Route path="/invoice-details" element={<InvoiceDetails />} /> 
    <Route path="/businesscropsdetails" element={<BusinessCROPsDetails />} />
  </Routes> 
  )
}

export default AccountManagementRoute