const ADMIN_LOGIN_URLS = {
    LOGIN:"auth/admin_login",
    LOGIN_BUSINESS:"auth/business_login"
    
}
const ADMIN_PROFILE_URLS={
    UPDATE_PROFILE:"user/update_admin_profile"
}

const BUSINESS_LOGIN_URLS = {
    SEND_OTP:"auth/send_otp",
    SEND_OTP_FORGOT:"auth/send_otp_forgot",
    VERIFY_BUSINESS_DETAILS:"auth/verify_business",
    RESET_PASSWORD_OTP:"auth/reset_pass_otp",
    SEND_ADMIN_OTP: "auth/send_otp_admin_forgot"
}

const USER_URLS = {
    INSERT_BUSINESS:"user/insert_business",
    GET_ONE_PROFILE:"user/get_one_profile",
    GET_ALL_ADMIN:"user/get_all",
    UPDATE_STATUS:"user/update",
    CHANGE_PASSWORD:"/user/change_password",
    GET_ALL_SELECT_BUSINESS:"/user/get_all_select_business"
}

const DATABASE_URLS={
    GET_ALL_LIKES:"business/likes/get_all_offer",
    GET_ALL_RATING:"business/ratings/get_all_offer",
    GET_ALL_AUDIT:"user/customer_audit/get_all",
    GET_ALL_INVOICE:"business/offers/get_all_invoice",
    GET_ALL_PROFILE:"user/get_one",

}

export {
    ADMIN_LOGIN_URLS,
    BUSINESS_LOGIN_URLS,
    USER_URLS,
    ADMIN_PROFILE_URLS,
    DATABASE_URLS
}