import React, { useEffect, useState } from "react";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { ADMIN_OPERATIONS_HOLIDAYS } from "../../../api/Admin/OperationsUrl";
import {
  SmartSoftButton,
  SmartSoftDate,
  SmartSoftForm,
  SmartSoftInput,
  SmartValid,
} from "soft_digi";
import { post, get } from "../../../services/smartApiService";
import { ALLOW_FLOAT } from "../../../services/PatternSerivce.js";
import { showNotification } from "../../../services/notifyService";
import { CommercialNotification } from "./CommercialConfig";
import SmartAccordion from "../../../components/site/FormElements/SmartAccordion";
import { ADMIN_SETTINGS } from "../../../api/Admin/OperationsUrl";
import { showAlertAutoClose } from "../../../services/notifyService";

const CommercialForm = (editData) => {
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const { setLoading, closeModal } = useSiteContext();
  const [formErrors, setFormErrors] = useState(false);

  const numericValidations = [
    {
      type: "required",
      msg: " is Required",
    },
  ];
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };
  const loadSettingsData = () => {
    setLoading(true, "Logging in Please Wait....");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    const subscription = get(ADMIN_SETTINGS.GET_ALL, handleError).subscribe(
      (response) => {
        setFormData(response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };
  useEffect(() => {
    loadSettingsData();
  }, []);

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const CommercialFormValidations = {
    BasePrices: [SmartValid.required("Base Prices is Required")],
  };
  const handleSubmit = () => {
    setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Details Submitting Please Wait....");
    let url = ADMIN_SETTINGS.INSERT;
    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        showAlertAutoClose("Data Saved Successfully", "success");
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const getFormElements = (settings) => {
    let formElements = [];
    for (let i = 0; i < settings.length; i++) {
      formElements.push({
        type: "TEXT_BOX",
        width: "6",
        name: settings[i].name,
        element: {
          label: settings[i].title,
          validations: CommercialFormValidations.BasePrices,
          inputType: "BORDER_LABEL",
          inputProps: { isFocussed: true },
          max: 10,
          pattern: ALLOW_FLOAT,
        },
      });
    }
    return formElements;
  };

  const data = [
    {
      title: "Updated Base Prices",
      body: (
        <CommercialNotification
          getFormElements={getFormElements}
          formData={formData}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          errorEnable={formSubmit}
          errorUpdate={(value) => handleErrorChange("euserid", value)}
          validations={CommercialFormValidations.BasePrices}
        />
      ),
    },
  ];

  return (
    <>
      <div className="crop-admin-form commercial-form smart-admin-crop-container ">
        <div className="column is-1 crop-admin-table-back">
          {/* <span className="icon">
            <i className="fa fa-undo" aria-hidden="true"></i>
          </span> */}
        </div>
        <SmartAccordion data={data} class_name="crop-admin-accord-card mb-5" />
      </div>
    </>
  );
};

export default CommercialForm;
