import React, { useState } from 'react'
import { SmartSoftCheckRadioSwitch, SmartSoftTable } from 'soft_digi';

const UserManagementTable = () => {

 
  

  

  return (
    <>
   


    </>
  );
};
export default UserManagementTable