import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SmartSoftTable } from "soft_digi";
import { SmartSoftCheckRadioSwitch } from "soft_digi";
import ComplaintForm from "./ComplaintForm";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { SERVICES_COMPLAINT_URLS } from "../../../../api/Services/ServiceUrls";
import { showNotification } from "../../../../services/notifyService";
import { post } from "../../../../services/smartApiService";

const CompaintTable = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState("1");
  const [tabData, setTabData] = useState([]);

  const baseNav = "/operations";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const MyOfferSwitch = () => {
    // const [formData, setFormData] = useState({});
    // const [formSubmit, setFormSubmit] = useState(false);
    // const [formErrors, setFormErrors] = useState(false);
    const Customer_complaint = [{ value: "1", label: "Customer Complaint" }];
    const Business_complaint = [{ value: "2", label: "Business Complaint" }];

    // const handleInputChange = (name, value) => {
    //   setFormData((prev) => ({ ...prev, [name]: value }));
    // };
    return (
      <>
        <div className="is-flex">
          <div className="my-offer-month-switch mr-5">
            <SmartSoftCheckRadioSwitch
              options={Customer_complaint}
              switchMode={true}
              isRight={true}
              name="switchone"
              value={category}
              onChange={(value) => setCategory("1")}
            />
          </div>
          <div className="my-offer-year-switch">
            <SmartSoftCheckRadioSwitch
              options={Business_complaint}
              switchMode={true}
              isRight={true}
              name="switch"
              value={category}
              onChange={(value) => setCategory("2")}
            />
          </div>
        </div>
      </>
    );
  };

  const { setLoading, openModal } = useSiteContext();

  const viewEditForm = (id, action_name) => {
    setLoading(true, "Please Wait....");
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setLoading(false);
    };
    const subscription = post(
      SERVICES_COMPLAINT_URLS.GET_ONE,
      { id: id },
      handleError
    ).subscribe((response) => {
      // console.log("response ", response);
      openNewForm(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const openNewForm = (data = {}) => {
    let modalObject = {
      body: <ComplaintForm loadTableData={loadTableData} dataIn={data} />,
      modalClass: "crop-admin-modal smart-modal-50",
      bodyClose: false,
    };
    openModal(modalObject);
  };

  const nameFunction = (row) => {
    return (
      <>
        <div
          className="is-flex is-clickable has-text-info"
          onClick={() => viewEditForm(row["ID"])}
        >
          <figure>{row["complaint_id"] || ""}</figure>
        </div>
      </>
    );
  };

  const loadTableData = () => {
    setLoading(true, "Logging in Please Wait....");
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setTabData([]);
      setLoading(false);
    };
    let url = SERVICES_COMPLAINT_URLS.GET_ALL_ADMIN;
    let post_data = {
      complaint_mode: parseInt(category) === 1 ? "CUSTOMER" : "BUSINESS",
    };
    const subscription = post(url, post_data, handleError).subscribe(
      (response) => {
        setTabData(response.data);
        // console.table("testing", response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, [category]);

  const columns = [
    { title: "NUMBER", index: "complaint_id", valueFunction: nameFunction },
    {
      title: "DATE RAISED",
      index: "complaint_date",
      type: "date",
      dateFormat: "DD/MM/YYYY",
      class: ["m-0"],
    },
    { title: "TYPE", index: "complaint_type" },
    { title: "DETAILS", index: "complaint_description" },
    { title: "PCM", index: "complaint_pcm" },
    {
      title: "ACTIONED",
      index: "complaint_action_date",
      type: "date",
      dateFormat: "DD/MM/YYYY",
    },
    { title: "STATUS", index: "complaint_status" },
    { title: "RESPONSE", index: "complaint_resolution" },
  ];

  // const CustomerComplaintData = [
  //   {
  //     number: "R-8070707",
  //     dateraised: "2023/09/26",
  //     type: "Appliction Is Very Slow",
  //     details: "Performance",
  //     pcm: "App",
  //     actioned: "2023/09/26",
  //     status: "Closed",
  //     response: "Application Number is closed",
  //   },
  //   {
  //     number: "R-8070707",
  //     dateraised: "2023/09/26",
  //     type: "Asd",
  //     details: "Complaint",
  //     pcm: "9807654321",
  //     actioned: "2023/09/26",
  //     status: "Progress",
  //     response: "",
  //   },
  //   {
  //     number: "R-9279456",
  //     dateraised: "2023/09/26",
  //     type: "Asdfg",
  //     details: "Complaint",
  //     pcm: "9807654321",
  //     actioned: "2023/09/26",
  //     status: "Open",
  //     response: "",
  //   },
  // ];

  // const BusinessComplaintData = [
  //   {
  //     number: "R-8070707",
  //     dateraised: "2023/09/26",
  //     type: "Appliction Is Very Slow",
  //     details: "Performance",
  //     pcm: "App",
  //     actioned: "2023/09/26",
  //     status: "received",
  //     response: "Thank You",
  //   },
  // ];
  return (
    <>
      <div className="smart-crop-admin-container">
        <div key={"CompaintTablecolumnsone"} className="columns">
          <div key={"CompaintTablecolumnone"} className="column">
            <div key={"CompaintTablecardone"} className="card">
              <div
                key={"CompaintTablecolumnstwo"}
                className="columns is-vcentered"
              >
                <div
                  key={"CompaintTablecolumntwo"}
                  className="column is-1 crop-admin-table-back"
                >
                  <span className="icon" onClick={() => navigateLink("home")}>
                    <i className="fa fa-undo" aria-hidden="true"></i>
                  </span>
                </div>
                <div
                  key={"CompaintTablecolumnthree"}
                  className="column is-8 crop-admin-table-title"
                >
                  {MyOfferSwitch()}
                </div>

                <div
                  key={"CompaintTablecolumnfour"}
                  className="column is-3 has-text-right crop-table-top-pagination"
                >
                  {/* <i className="fa fa-caret-left" aria-hidden="true"></i>{" "} */}
                  {/* <i className="fa fa-caret-right" aria-hidden="true"></i> */}
                </div>
              </div>
              <SmartSoftTable
                columns={columns}
                data={tabData}
                tableProps={{ className: "crop-table-layout" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompaintTable;
