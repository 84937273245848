// LoginLayout.js
import React,{useState} from 'react';
import { useSiteContext } from '../../contexts/SiteProvider';
import './AdminLayoutCss.css';
import AdminSideNav from './AdminSideNav';
import AdminHeader from './AdminHeader';
import { CROP_ADMIN_LOGO } from "../../services/ImageService";


const AdminLayout = ({ children }) => {
  const { user, setUser } = useSiteContext();
  const [page, setPage] = useState("home");
  const [isOpen, setIsOpen] = useState(false);
  const handleLogout = () => {
    setUser();
  }
  const toggleSidebar = () => {
    setIsOpen(!isOpen); // Toggle the state
  };
  return (
    <div className="container is-fluid smart-crop-admin-container">
         <div  className={`sidebar ${isOpen ? 'smart-crop-admin-sidenav-open' : 'smart-crop-admin-sidenav'}`} >
         <div className="is-flex is-justify-content-center mt-5" >
            <img className="crops-logo" src={CROP_ADMIN_LOGO} />
        </div > 
        <div className='smart-crop-admin-side-bottom'>
        <AdminSideNav page={page} />
        </div>
          
         </div> 
         <div  className={`sidebar ${isOpen ? 'smart-crop-admin-middle-open' : 'smart-crop-admin-middle'}`} >
              <div className='smart-crop-admin-header'>
                  <AdminHeader toggleSidebar={toggleSidebar} setPage={setPage} handleLogout={handleLogout} />
              </div>
              <div className='smart-crop-admin-content'>
                  {children}
              </div>
         </div>
    </div>
  );
};

export default AdminLayout;
