import React, { useState } from 'react'
import { SmartSoftTable } from 'soft_digi';
import { useNavigate } from "react-router-dom";
import { useSiteContext } from '../../../contexts/SiteProvider';

const PropsTable = () => {

    const [tabData, setTabData] = useState([]);
    const { setLoading, openModal, closeModal } = useSiteContext();
    const navigate = useNavigate();

    const baseNav = "/admin/database";
    const navigateLink = (index) => {
        navigate(baseNav + "/" + index);
    };

    const columns = [
        { title: "DATE", index: "dateline", },
        { title: "ORDER NUMBER", index: "orderline", },
        { title: "AMOUNT", index: "amountline", },
        { title: "DESCRIPTION", index: "descriptionline", },
        { title: "DEBIT", index: "debitline", },
        { title: "CREDIT", index: "creditline", },
    ];
    const data = [
        { dateline: '22/05/2023', orderline: 'pLgvbkjljjvgjghhghg', amountline: '1000', descriptionline: 'Purchased PROP', debitline: '', creditline: '1000.00' },
        { dateline: '19/05/2023', orderline: 'pLgvbkjljjvgjghhghg', amountline: '1000', descriptionline: 'Purchased PROP', debitline: '', creditline: '1000.00' },
        { dateline: '05/05/2023', orderline: 'pLgvbkjljjvgjghhghg', amountline: '100', descriptionline: 'Purchased PROP', debitline: '', creditline: '100.00' },
        { dateline: '04/05/2023', orderline: 'pLgvbkjljjvgjghhghg', amountline: '2000', descriptionline: 'Purchased PROP', debitline: '', creditline: '2000.00' },
        { dateline: '04/05/2023', orderline: 'pLgvbkjljjvgjghhghg', amountline: '100', descriptionline: 'Purchased PROP', debitline: '', creditline: '100.00' },
        { dateline: '03/05/2023', orderline: 'pLgvbkjljjvgjghhghg', amountline: '100', descriptionline: 'Purchased PROP', debitline: '', creditline: '100.00' },
    ]

    return (
        <div className='PropsTable'>
            <div  key={"PropsTableFirstColumns"} className="columns">
                <div  key={"PropsTableFirstColumn"} className="column">
                    <div className='card'>
                        <div className="smart-crop-admin-container">
                            <div  key={"PropsTableSecondColumns"} className="columns is-vcentered">
                                <div  key={"PropsTableSecondColumn"} className="column is-1 crop-admin-table-back">
                                    <span className="icon" onClick={() => navigateLink("database_management-list")}>
                                        <i className="fa fa-undo" aria-hidden="true"></i>
                                    </span>
                                </div>
                                <div  key={"PropsTableThirdColumn"} className="column is-8 crop-admin-table-title">
                                    Customer PROPs Details
                                </div>
                                <div  key={"PropsTableFourthColumn"} className="column is-3 has-text-right crop-table-top-pagination">
                                    <i className="fa fa-caret-left" aria-hidden="true"></i>{" "}
                                    <i className="fa fa-caret-right" aria-hidden="true"></i>
                                </div>
                            </div>
                            <SmartSoftTable columns={columns} data={data} tableProps={{ className: "crop-table-layout" }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PropsTable
