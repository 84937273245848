import { SmartSoftTable } from "soft_digi";
import { useNavigate } from "react-router-dom";

const PaymentHistoryTable = () => {
  const navigate = useNavigate();
  const basenav = "/operations";

  const navigateLink = (index) => {
    navigate(basenav + "/" + index);
  };

  const columns = [
    { title: "DATE", index: "date", type: "date", dataFormat: "DD/MM/YYYY" },
    { title: "TITLE", index: "title" },
    { title: "PRICE", index: "price" },
    { title: "CROPs", index: "crops" },
    { title: "QUANTITY", index: "qty" },
    { title: "TOTAL CROPs", index: "totalcrops" },
    { title: "TOTAL", index: "total" },
    { title: "GST 10%", index: "gst" },
    { title: "CROPs VALUES", index: "cropsvalues" },
    { title: "CROPs RETENTION 5%", index: "cropsretention" },
    { title: "TO PAY", index: "topay" },
  ];

  const data = [
    {
      date: "",
      title: "",
      price: 250,
      crops: "",
      qty: 5,
      totalcrops: "",
      total: "",
      gst: "",
      cropsvalues: "Total to pay",
      cropsretention: 0.0,
      topay: "",
    },
  ];
  return (
    <>
      <div className="smart-crop-admin-container">
        <div
          key={"PaymentHistoryTablecolumnsone"}
          className="columns is-vcentered"
        >
          <div
            key={"PaymentHistoryTablecolumnone"}
            className="column is-1 crop-admin-table-back"
          >
            <span className="icon" onClick={() => navigateLink("payment-list")}>
              <i className="fa fa-undo" aria-hidden="true"></i>{" "}
            </span>
          </div>
          <div
            key={"PaymentHistoryTablecolumntwo"}
            className="column is-8 crop-admin-table-title"
          >
            HISTORY
          </div>
          <div
            key={"PaymentHistoryTablecolumnthree"}
            className="column is-3 has-text-right crop-table-top-pagination"
          >
            {/* <i className="fa fa-caret-left" aria-hidden="true"></i>{" "} */}
            {/* <i className="fa fa-caret-right" aria-hidden="true"></i> */}
          </div>
        </div>
        <SmartSoftTable
          columns={columns}
          data={data}
          tableProps={{ className: "crop-table-layout" }}
        />
      </div>
    </>
  );
};

export default PaymentHistoryTable;
