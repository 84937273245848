import React, { useState } from "react";
import "./AdminLogin.css";
import {
  CROP_ADMIN_FORGOT_IMAGE,
  CROP_ADMIN_LOGIN_IMAGE,
  CROP_LOGO,
} from "../../../services/ImageService";
import { SmartSoftButton, SmartSoftInput } from "soft_digi";
import { useNavigate } from "react-router-dom";
import { isEmptyObject } from "../../../services/core/CommonService";
import { showAlertAutoClose } from "../../../services/notifyService";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { BUSINESS_LOGIN_URLS } from "../../../api/Admin/AurhUrls";
import { post } from "../../../services/smartApiService";

const AdminForgot = () => {
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading } = useSiteContext();
  const navigate = useNavigate();

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));

    const handleErrorChange = (name, value) => {
      setFormErrors((prev) => {
        // Create a copy of the previous state
        const updatedFormData = { ...prev };
        // Check if the value is null or empty
        if (value === null || value === "") {
          // If true, remove the property from the updated state
          delete updatedFormData[name];
        } else {
          // Otherwise, update or add the property in the updated state
          updatedFormData[name] = value;
        }

        return updatedFormData;
      });
    };
  };

  const handleLogin = (e) => {
    setFormSubmit(true);
    if (!isEmptyObject(formErrors)) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Sending OTP Please Wait....");
    let url = BUSINESS_LOGIN_URLS.SEND_ADMIN_OTP;
    const subscription = post(url, formData, handleError, false).subscribe(
      (response) => {
        handleInputChange("otp_id", response.data.otp_id);
        handleInputChange("uid", response.data.uid);
        showAlertAutoClose("OTP Sent Successfully ", "success");
        setLoading(false);
        // setStage("SECOND");
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const handleVerify = () => {
    //setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Verifying the details... Please wait");
    // console.log("fdata ", formData);
    let url = BUSINESS_LOGIN_URLS.RESET_PASSWORD_OTP;
    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        let msg = response?.data?.msg || "Password Reset Succesfully";
        showAlertAutoClose(msg, "success");
        setLoading(false);
        navigate("/login");
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  return (
    <>
      <div className="admin-login-main-container">
        <div className="admin-login-container">
          <div className="columns">
            <div className="column admin-login-image-view ">
              <div className="login-image-view">
                <div className="admin-forgot-image is-hidden-mobile ">
                  <img
                    className="admin-forgot-images m-0 "
                    src={CROP_ADMIN_FORGOT_IMAGE}
                    alt="admin-forget-image"
                  />
                </div>

                <div className="admin-login-form-view">
                  <div className=" admin-forgot-main-card ">
                    <div className="card  admin-forgot-card">
                      <div className="card-image has-text-centered">
                        <figure class="imageimage-Admin-Forgot is-flex is-justify-content-center">
                          <img
                            className="image-Admin-Forgot "
                            src={CROP_LOGO}
                            alt="Placeholder"
                          />
                        </figure>
                      </div>
                      <div className="admin-forgot-text">Forget Password</div>
                      <div className="admin-login-input">
                        <div className="admin-login-input-details mt-5 my-5">
                          <SmartSoftInput
                            key="text-4"
                            label="Enter Your Email"
                            value={formData?.email_id || ""}
                            onChange={(value) =>
                              handleInputChange("email_id", value)
                            }
                            inputType="BORDER_LABEL_FOCUS"
                            leftIcon="fa fa-envelope-o"
                            inputProps={{ isFocussed: true }}
                          />
                        </div>

                        <div className="admin-button">
                          <SmartSoftButton
                            label="Submit"
                            classList={["admin-login-button"]}
                            onClick={() => handleLogin()}
                          />
                        </div>
                        <div className="go-to-login-text">
                          <a href="/">Go to Login</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminForgot;
