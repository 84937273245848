import { useState } from "react";
// import { showNotification } from '../../../services/notifyService';
import {
  SmartSoftButton,
  SmartSoftForm,
  SmartSoftInput,
  SmartSoftTable,
} from "soft_digi";
import { SmartSoftCheckRadioSwitch } from "soft_digi";
import { useSiteContext } from "../../../../contexts/SiteProvider";

const Marcom = () => {
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const { setLoading, closeModal } = useSiteContext();
  const [category, setCategory] = useState("1");

  const numericValidations = [
    {
      type: "required",
      msg: " is Required",
    },
  ];

  // useEffect(() => {
  //   if (editData) {
  //     setFormData(editData);
  //   }
  // }, [editData]);

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleSubmit = () => {
    setFormSubmit(true);
    const handleError = (errorMessage) => {
      // showNotification("error", errorMessage);
      setLoading(false);
    };
    // setLoading(true, "Details Submitting Please Wait....");
    // let url = ADMIN_OPERATIONS_HOLIDAYS.INSERT;
    // if(formData.ID!==undefined){
    //   formData["id"] = formData.ID;
    //   url = ADMIN_OPERATIONS_HOLIDAYS.UPDATE;
    // }

    // const subscription = post(
    //   url,
    //   formData,
    //   handleError
    // ).subscribe((response) => {
    //   showNotification("success","Data Saved Successfully");
    //   setFormData({holiday_name:'',holiday_date:'',state_name:''})
    //   // loadTableData();
    //   setLoading(false);
    // });
    // return () => {
    //   subscription.unsubscribe();
    // };
  };

  const formElements = [
    {
      type: "TEXT_BOX",
      width: "4",
      name: "",
      element: {
        label: "Customer Name",
        placeHolder: "Customer Name",
        //   validations:numericValidations,
        inputType: "BORDER_LABEL",
        inputProps: { isFocussed: true },
      },
    },
    {
      type: "TEXT_BOX",
      width: "4",
      name: "",
      element: {
        label: "Email",
        placeHolder: "Email",
        //   validations:numericValidations,
        inputType: "BORDER_LABEL",
        inputProps: { isFocussed: true },
      },
    },
    {
      type: "TEXT_BOX",
      width: "4",
      name: "",
      element: {
        label: "Address",
        placeHolder: "Address",
        //   validations:numericValidations,
        inputType: "BORDER_LABEL",
        inputProps: { isFocussed: true },
      },
    },
  ];
  const Elements = [
    {
      type: "TEXT_BOX",
      width: "8",
      name: "",
      element: {
        label: "Notification Text",
        placeHolder: "Notification Text",
        //   validations:numericValidations,
        inputType: "BORDER_LABEL",
        inputProps: { isFocussed: true },
      },
    },
  ];

  const BusinessElements = [
    {
      type: "TEXT_BOX",
      width: "4",
      name: "",
      element: {
        label: "Business Name",
        placeHolder: "Business Name",
        //   validations:numericValidations,
        inputType: "BORDER_LABEL",
        inputProps: { isFocussed: true },
      },
    },
    {
      type: "TEXT_BOX",
      width: "4",
      name: "",
      element: {
        label: "PIN",
        placeHolder: "PIN",
        //   validations:numericValidations,
        inputType: "BORDER_LABEL",
        inputProps: { isFocussed: true },
      },
    },
    {
      type: "TEXT_BOX",
      width: "4",
      name: "",
      element: {
        label: "Address",
        placeHolder: "Address",
        //   validations:numericValidations,
        inputType: "BORDER_LABEL",
        inputProps: { isFocussed: true },
      },
    },
  ];

  const MyOfferSwitch = () => {
    const [formData, setFormData] = useState({});
    const [formSubmit, setFormSubmit] = useState(false);
    const [formErrors, setFormErrors] = useState(false);
    const Customer_account = [{ value: "1", label: "Customer Account" }];
    const Business_account = [{ value: "2", label: "Business Account" }];

    const handleInputChange = (name, value) => {
      setFormData((prev) => ({ ...prev, [name]: value }));
    };
    return (
      <>
        <div className="is-flex">
          <div className="my-offer-month-switch mr-5">
            <SmartSoftCheckRadioSwitch
              options={Customer_account}
              switchMode={true}
              isRight={true}
              name="switchone"
              value={category}
              onChange={(value) => setCategory("1")}
            />
          </div>
          <div className="my-offer-year-switch">
            <SmartSoftCheckRadioSwitch
              options={Business_account}
              switchMode={true}
              isRight={true}
              name="switch"
              value={category}
              onChange={(value) => setCategory("2")}
            />
          </div>
        </div>
      </>
    );
  };

  const columns = [
    { title: "NAME", index: "name" },
    { title: "EMAIL", index: "email" },
    { title: "ADDRESS", index: "address" },
  ];

  const Businesscolumns = [
    { title: "NAME", index: "name" },
    { title: "NATURE OF BUSINESS", index: "business" },
    { title: "EMAIL", index: "email" },
    { title: "ADDRESS", index: "address" },
  ];

  const data = [
    {
      name: "predeep",
      email: "pradeepswain2165@gmail.com",
      address:
        "Address1: 73, Cecil Street, Dundas Valley, New South Wales, 2117",
    },
  ];

  const businessdata = [
    {
      name: "predeep",
      business: "Manufacturing",
      email: "pradeepswain2165@gmail.com",
      address:
        "Address1: 73, Cecil Street, Dundas Valley, New South Wales, 2117",
    },
  ];

  return (
    <>
      <div className="crop-admin-form smart-admin-crop-container">
        <div key={"Marcomcolumnsone"} className="columns is-vcentered">
          <div key={"Marcomcolumnone"} className="column crop-admin-table-back">
           
          </div>
          <div
            key={"Marcomcolumntwo"}
            className="column is-8 crop-admin-table-title"
          >
            {MyOfferSwitch()}
          </div>
          <div
            key={"Marcomcolumnthree"}
            className="column is-3 has-text-right crop-table-top-pagination"
          >
          </div>
        </div>

        <div className="smart-crop-admin-holiday-card pl-4 is-size-5">
          Marcom (Marketing and Communication)
        </div>

        <div className="mt-6 p-5">
          <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={formElements}
            formSubmit={formSubmit}
            //   handleErrorChange={handleErrorChange}
          />
        </div>

        <div>
          <SmartSoftTable
            columns={columns}
            data={data}
            tableProps={{ className: "crop-table-layout" }}
          />
        </div>
        <div key={"Marcomcolumnstwo"} className="columns mt-6 p-5">
          <div key={"Marcomcolumnfour"} className="column is-8">
            <SmartSoftForm
              formData={formData}
              setFormData={handleInputChange}
              elements={Elements}
              formSubmit={formSubmit}
              //   handleErrorChange={handleErrorChange}
            />
          </div>
          <div key={"Marcomcolumnfive"} className="column is-4 ">
            <SmartSoftButton
              classList={["smart-crop-admin-action-button", "px-6"]}
              label="SEND MASS NOTIFICATION"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>

      <div className="crop-admin-form smart-admin-crop-container">
        <div key={"Marcomcolumnsthree"} className="columns is-vcentered">
          <div key={"Marcomcolumnsix"} className="column crop-admin-table-back">
            
          </div>
          <div
            key={"Marcomcolumnseven"}
            className="column is-8 crop-admin-table-title"
          >
            {MyOfferSwitch()}
          </div>
          <div
            key={"Marcomcolumneight"}
            className="column is-3 has-text-right crop-table-top-pagination"
          >
       
          </div>
        </div>

        <div className="smart-crop-admin-holiday-card pl-4 is-size-5">
          Marcom (Marketing and Communication)
        </div>

        <div className="mt-6 p-5">
          <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={BusinessElements}
            formSubmit={formSubmit}
            //   handleErrorChange={handleErrorChange}
          />
        </div>

        <div>
          <SmartSoftTable
            columns={Businesscolumns}
            data={businessdata}
            tableProps={{ className: "crop-table-layout" }}
          />
        </div>
        <div key={"Marcomcolumnsfour"} className="columns mt-6 p-5">
          <div key={"Marcomcolumnnine"} className="column is-8">
            <SmartSoftForm
              formData={formData}
              setFormData={handleInputChange}
              elements={Elements}
              formSubmit={formSubmit}
              //   handleErrorChange={handleErrorChange}
            />
          </div>
          <div key={"Marcomcolumnten"} className="column is-4 ">
            <SmartSoftButton
              classList={["smart-crop-admin-action-button", "px-6"]}
              label="SEND MASS NOTIFICATION"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Marcom;
