import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SmartSoftTable } from "soft_digi";
import { SmartSoftCheckRadioSwitch } from "soft_digi";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import TierStatus from "./TierStatus";

const TierTable = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState("1");

  const baseNav = "/operations";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const MyOfferSwitch = () => {
    const [formData, setFormData] = useState({});
    const [formSubmit, setFormSubmit] = useState(false);
    const [formErrors, setFormErrors] = useState(false);
    const Customer_data = [{ value: "1", label: "Customer Data" }];
    const Business_data = [{ value: "2", label: "Business Data" }];

    const handleInputChange = (name, value) => {
      setFormData((prev) => ({ ...prev, [name]: value }));
    };
    return (
      <>
        <div className="is-flex">
          <div className="my-offer-month-switch mr-5">
            <SmartSoftCheckRadioSwitch
              options={Customer_data}
              switchMode={true}
              isRight={true}
              name="switchone"
              value={category}
              onChange={(value) => setCategory("1")}
            />
          </div>
          <div className="my-offer-year-switch">
            <SmartSoftCheckRadioSwitch
              options={Business_data}
              switchMode={true}
              isRight={true}
              name="switch"
              value={category}
              onChange={(value) => setCategory("2")}
            />
          </div>
        </div>
      </>
    );
  };

  const nameTags = [
    {
      value: "suspended",
      label: "Suspended",
      class: ["admin-crop-suspended-status is-rounded is-small m-0"],
    },
    {
      value: "presuspended",
      label: "Presuspended",
      class: ["admin-crop-presuspended-status is-rounded is-small m-0"],
    },
    {
      value: "active",
      label: "Active",
      class: ["admin-crop-active-status is-rounded is-small m-0"],
    },
  ];

  const { setLoading, openModal, closeModal } = useSiteContext();
  const openNewForm = (data = {}) => {
    let modalObject = {
      body: <TierStatus loadTableData dataIn />,
      modalClass: "crop-admin-modal smart-modal-50",
      bodyClose: false,
    };
    openModal(modalObject);
  };

  const buttons = [
    {
      label: "Change",
      leftIcon: "",
      classList: ["admin-crop-presuspended-status is-rounded is-small m-0"],
      onClick: () => {
        openNewForm();
      },
    },
  ];

  //Customer Data Tier Table
  const Customercolumns = [
    { title: "CUSTOMER NAME", index: "customername" },
    { title: "CROP ID", index: "cropid" },
    { title: "STATUS", index: "status", type: "tags", tags: nameTags },
    { title: "TIER", index: "tier" },
    { title: "EARN CROPs", index: "earncrops" },
    {
      title: "LAST CHANGE DATE",
      index: "lastchangedate",
      type: "date",
      dateFormat: "DD/MM/YYYY",
    },
    {
      title: "CHANGE TIER",
      index: "changetire",
      type: "buttons",
      buttons: buttons,
    },
  ];

  const CustomerTableData = [
    {
      customername: "predeep kumar swain",
      cropid: 100004,
      status: "suspended",
      tier: "Blue",
      earncrops: 1085.25,
      lastchangedate: "2023/05/02",
    },
  ];

  //Business Data Tier Table
  const Businesscolumns = [
    { title: "CUSTOMER NAME", index: "customername" },
    { title: "CROP ID", index: "cropid" },
    { title: "STATUS", index: "status", type: "tags", tags: nameTags },
    { title: "TIER", index: "tier" },
    { title: "EARN CROPs", index: "offeredcrops" },
    {
      title: "LAST CHANGE DATE",
      index: "lastchangedate",
      type: "date",
      dateFormat: "DD/MM/YYYY",
    },
    {
      title: "CHANGE TIER",
      index: "changetire",
      type: "buttons",
      buttons: buttons,
    },
  ];

  const BusinessTableData = [
    {
      customername: "Arrium",
      cropid: "BUS0000094",
      status: "active",
      tier: "B",
      offeredcrops: 0.0,
      lastchangedate: "2023/05/02",
    },
  ];

  return (
    <>
      <div className="smart-crop-admin-container">
        <div className="columns">
          <div className="column">
            <div className="card">
              <div className="columns is-vcentered">
                <div className="column is-1 crop-admin-table-back">
                  <span className="icon" onClick={() => navigateLink("home")}>
                    <i className="fa fa-undo" aria-hidden="true"></i>
                  </span>
                </div>
                <div className="column is-8 crop-admin-table-title">
                  {MyOfferSwitch()}
                </div>

                <div className="column is-3 has-text-right crop-table-top-pagination">
                  {/* <i className="fa fa-caret-left" aria-hidden="true"></i>{" "} */}
                  {/* <i className="fa fa-caret-right" aria-hidden="true"></i> */}
                </div>
              </div>
              <SmartSoftTable
                columns={Customercolumns}
                data={CustomerTableData}
                tableProps={{ className: "crop-table-layout" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TierTable;
