import React, { useState } from "react";
import { SmartSoftButton, SmartSoftForm } from "soft_digi";
import { ACCOUNT_SECURITY } from "../../../services/ImageService";
import { useNavigate } from "react-router-dom";
import { showNotification } from "../../../services/notifyService";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { USER_URLS } from "../../../api/Admin/AurhUrls";
import { post } from "../../../services/smartApiService";
import SmartPassWord from "../../../components/site/FormElements/SmartPassWord";
import { SmartValid } from "soft_digi";
import { VALID_PASSWORD_GENERAL } from "../../../services/PatternSerivce.js";

const AccountSecurity = () => {
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const [tab, setTab] = useState("security");
  const { setLoading } = useSiteContext();

  const navigate = useNavigate();
  const baseNav = "/account-settings";

  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const numericValidations = [
    {
      type: "required",
      msg: "Numeric Value is Required",
    },
    {
      type: "pattern",
      msg: "Please Enter Correct Numeric Value",
      pattern: "[0-9]+",
    },
  ];

  const handleFormSubmit = () => {
    // console.log("formdata", formData);
    setFormSubmit(true);
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setLoading(false);
    };
    setLoading(true, "Details Submitting Please Wait....");
    // console.log(formData);
    let url = USER_URLS.CHANGE_PASSWORD;
    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        showNotification("success", "Password Changed Successfully");
        setLoading(false);
        // console.log(response.data);
        setFormData({});
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };
  const tabs = [
    {
      index: "account",
      label: "Account",
      icon: <i className="fa fa-user-circle-o" aria-hidden="true"></i>,
      link: "account",
    },
    {
      index: "security",
      label: "Security",
      icon: <i className="fa fa-unlock-alt" aria-hidden="true"></i>,
      link: "security",
    },
  ];

  const handleTabs = (index) => {
    setTab(index);
  };

  const tabs_display = () => {
    return (
      <div className="tabs is-boxed is-medium is-8 crop-top-tabs account-settings-tab">
        <ul>
          {tabs.map((item, index) => {
            return (
              <li
                key={index}
                onClick={() => handleTabs(item.index)}
                className={tab === item.index ? "is-active" : ""}
              >
                <a onClick={() => navigateLink(item.link)}>
                  <span>{item.icon}</span>
                  <span className="ml-3">{item.label}</span>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };
  const AccountSecurityFormValidations = {
    EnterOldPassword: [SmartValid.required("Enter Old Password is Required")],
    EnterNewPassword: [SmartValid.required("Enter New Password is Required")],
    ConfirmNewPassword: [
      SmartValid.required("Confirm New Password is Required"),
    ],

    // EnterOldPassword:[SmartValid.minLength("Number should be minimum 10 digit", 9)],
  };
  const Feild = () => {
    const Elements = [
      {
        type: "TEXT_BOX",
        width: "12",
        name: "currentPassword",
        element: {
          label: "Current Password",
          placeHolder: "Enter Your Current Password",
          validations: AccountSecurityFormValidations.EnterOldPassword,
          inputType: "BORDER_LABEL",
          inputProps: { isFocussed: true },
          max: 8,
          pattern: VALID_PASSWORD_GENERAL,
        },
      },

      {
        type: "TEXT_BOX",
        width: "12",
        name: "newPassword",
        element: {
          label: "New Password",
          placeHolder: "Enter Your New Password",
          validations: AccountSecurityFormValidations.EnterNewPassword,
          inputType: "BORDER_LABEL",
          inputProps: { isFocussed: true },
          max: 8,
          pattern: VALID_PASSWORD_GENERAL,
        },
      },

      {
        type: "TEXT_BOX",
        width: "12",
        name: "confirmPassword",
        element: {
          label: "Confirm Password",
          placeHolder: "Enter Your New Password",
          validations: AccountSecurityFormValidations.ConfirmNewPassword,
          inputType: "BORDER_LABEL",
          inputProps: { isFocussed: true },
          max: 8,
          pattern: VALID_PASSWORD_GENERAL,
        },
      },
    ];

    return (
      <div className="crop-admin-form account-settings-form">
        <div className="m-6">
          {/* <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={Elements}
            formSubmit={formSubmit}
            handleErrorChange={handleErrorChange}
          /> */}
          <div className="pb-3 crop-admin-form">
            <SmartPassWord
              key="currentPassword"
              name="currentPassword"
              value={formData?.currentPassword || ""}
              handleInputChange={handleInputChange}
              inputType="BORDER_LABEL_FOCUS"
              label="Enter Old Password"
              elements={Elements}
              passwordValidator={true}
              errorEnable={formSubmit}
              min={8}
              max={30}
              errorUpdate={(value) => handleErrorChange("euserid", value)}
              validations={AccountSecurityFormValidations.EnterOldPassword}
            />
          </div>
          <div className="pb-3">
            <SmartPassWord
              key="newPassword"
              name="newPassword"
              inputType="BORDER_LABEL_FOCUS"
              label="Enter New Password"
              elements={Elements}
              value={formData?.newPassword || ""}
              handleInputChange={handleInputChange}
              passwordValidator={true}
              errorEnable={formSubmit}
              min={8}
              max={30}
              errorUpdate={(value) => handleErrorChange("euserid", value)}
              validations={AccountSecurityFormValidations.EnterNewPassword}
            />
          </div>
          <div className="pb-3">
            <SmartPassWord
              key="confirmPassword"
              name="confirmPassword"
              value={formData?.confirmPassword || ""}
              handleInputChange={handleInputChange}
              inputType="BORDER_LABEL_FOCUS"
              label="Confirm New Password"
              passwordValidator={true}
              elements={Elements}
              errorEnable={formSubmit}
              min={8}
              max={30}
              errorUpdate={(value) => handleErrorChange("euserid", value)}
              validations={AccountSecurityFormValidations.ConfirmNewPassword}
            />
          </div>
          <div className="mt-6">
            <SmartSoftButton
              label="Save Change"
              onClick={() => handleFormSubmit()}
              classList={["smart-crop-admin-action-button", "px-6"]}
              // disabled={!isEmptyObject(formErrors)}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="smart-admin-crop-container is-mobile">
        <div key={"AccountSecurityFirstcolumns"} className="columns">
          <div key={"AccountSecurityFirstcolumn"} className="column ">
            {tabs_display()}
          </div>
        </div>

        <div key={"AccountSecuritySecondcolumns"} className="columns">
          <div key={"AccountSecuritySecondcolumn"} className="column is-6 ">
            {Feild()}
          </div>

          <div key={"AccountSecurityThirdcolumn"} className="column is-6  mb-5">
            <div className="card-image ">
              <img
                className="my-card-view-form-image"
                src={ACCOUNT_SECURITY}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountSecurity;
