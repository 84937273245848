import React, { useState, useEffect } from "react";
import { SmartSoftTable } from "soft_digi";
import { useNavigate, useParams } from "react-router-dom";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { decrypt_data } from "../../../services/sessionService";
import { DATABASE_URLS, USER_URLS } from "../../../api/Admin/AurhUrls";
import { post } from "../../../services/smartApiService";
import { showAlertAutoClose } from "../../../services/notifyService";
import StarDisplay from "../../../components/site/StarDisplay";

const RateProductTable = () => {
  const { id } = useParams();
  const decodeId = decodeURIComponent(id);
  const decrypted_id = decrypt_data(decodeId);

  const [tabData, setTabData] = useState([]);
  const { setLoading, openModal, closeModal } = useSiteContext();
  const navigate = useNavigate();

  const baseNav = "/database";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };
  const loadTableData = () => {
    //setLoading(true, "Logging in Please Wait....");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    let post_data = { sd_offers_id: decrypted_id };
    let url = DATABASE_URLS.GET_ALL_RATING;
    const subscription = post(url, post_data, handleError).subscribe(
      (response) => {
        setTabData(response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, [decrypted_id]);

  const starbutton = () => {
    return (
      <>
        <StarDisplay />
      </>
    );
  };
  const columns = [
    { title: "SECTOR", index: "sector" },
    { title: "PRODUCT", index: "product" },
    { title: "TITLE", index: "title" },
    {
      title: "RATED",
      index: "rating ",
      valueFunction: starbutton,
    },
    { title: "COMMENT", index: "comment" },
  ];

  const data = [
    {
      sectorline: "Shops",
      prodectline: " ",
      titleline: "OnEar phone ",
      ratedline: "star ",
      commentline: "good",
    },
    {
      sectorline: "Shops",
      prodectline: " ",
      titleline: "OnEar phone ",
      ratedline: " star",
      commentline: "very good",
    },
  ];
  return (
    <>
      <div className="smart-crop-admin-container">
        <div key={"RateProductTablecolumnsone"} className="columns">
          <div key={"RateProductTablecolumnone"} className="column">
            <div key={"RateProductTablecardone"} className="card ">
              <div
                key={"RateProductTablecolumnstwo"}
                className="columns is-vcentered"
              >
                <div
                  key={"RateProductTablecolumntwo"}
                  className="column is-1 crop-admin-table-back"
                >
                  <span
                    className="icon"
                    onClick={() => navigateLink("database_management-list")}
                  >
                    <i className="fa fa-undo" aria-hidden="true"></i>
                  </span>
                </div>
                <div
                  key={"RateProductTablecolumnthree"}
                  className="column is-8 crop-admin-table-title"
                >
                  Rated Product
                </div>
                <div
                  key={"RateProductTablecolumnfour"}
                  className="column is-3 has-text-right crop-table-top-pagination"
                >
                  {/* <i className="fa fa-caret-left" aria-hidden="true"></i>{" "}
                    <i className="fa fa-caret-right" aria-hidden="true"></i> */}
                </div>
              </div>
              <SmartSoftTable
                columns={columns}
                data={tabData}
                tableProps={{ className: "crop-table-layout" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RateProductTable;
