import React, { useState } from "react";
import "./AdminLogin.css";
import {
  CROP_ADMIN_LOGIN_IMAGE,
  CROP_LOGO,
} from "../../../services/ImageService";
import { useNavigate } from "react-router-dom";
import {
  SmartSoftButton,
  SmartSoftCheckRadioSwitch,
  SmartSoftInput,
  SmartValid,
} from "soft_digi";
import { ADMIN_LOGIN_URLS } from "../../../api/Admin/AurhUrls";
import {
  showAlertAutoClose,
  showNotification,
} from "../../../services/notifyService";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { post } from "../../../services/smartApiService";

const AdminLogin = () => {
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading, setUser, startSessionAct } = useSiteContext();
  const navigate = useNavigate();

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };
  const handleSubmit = () => {
    //setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      //showNotification("error", errorMessage);
      setLoading(false);
    };
    setLoading(true, "Logging in Please Wait....");
    let url = ADMIN_LOGIN_URLS.LOGIN;
    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        showAlertAutoClose("Logged in Successfully", "success");
        //showNotification("success", "Logged in Successfully");
        setLoading(false);
        setUser(response.data);
        startSessionAct();
        navigate("/dashboard");
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };
  const AdminLoginFormValidations = {
    Email: [
      SmartValid.required("Please enter a valid Email address"),
      SmartValid.email("Please enter a valid Email address"),
    ],
    password: [SmartValid.required("Password is Required")],
  };
  const options = [{ value: "1", label: "" }];
  return (
    <>
      <div className="admin-main-container">
        <div className="admin-login-main-container">
          <div className="admin-login-container ">
            <div className="columns">
              <div className="column admin-login-main-card ">
                <div className="card  admin-login-card">
                  <div className="card-image has-text-centered">
                    <figure class="image is-flex is-justify-content-center">
                      <img
                        className="image admin-login-image "
                        src={CROP_LOGO}
                        alt=""
                      />
                    </figure>
                  </div>
                  <div className="admin-login-input">
                    <div className="admin-login-input-details mt-4 ">
                      <SmartSoftInput
                        key="text-4"
                        label="Email"
                        value={formData?.euserid || ""}
                        onChange={(value) =>
                          handleInputChange("euserid", value)
                        }
                        inputType="BORDER_LABEL"
                        leftIcon="fa fa-envelope-o"
                        inputProps={{ isFocussed: true }}
                        errorEnable={formSubmit}
                        errorUpdate={(value) =>
                          handleErrorChange("euserid", value)
                        }
                        validations={AdminLoginFormValidations.Email}
                      />
                    </div>
                    <div className="admin-login-input-details mt-4">
                      <SmartSoftInput
                        key="text-4"
                        label="Password"
                        type="password"
                        value={formData?.epassword || ""}
                        onChange={(value) =>
                          handleInputChange("epassword", value)
                        }
                        inputType="BORDER_LABEL"
                        leftIcon="fa fa-key"
                        inputProps={{ isFocussed: true }}
                        errorEnable={formSubmit}
                        errorUpdate={(value) =>
                          handleErrorChange("euserid", value)
                        }
                        validations={AdminLoginFormValidations.password}
                      />
                    </div>
                    <div className="admin-login-remember is-flex is-justify-content-space-around mt-3">
                      <div className="remember is-flex  ">
                        <SmartSoftCheckRadioSwitch
                          classList={["smart-admin-login-input"]}
                          options={options}
                          name="checkboxtwo"
                          value={formData?.checkboxtwo || null}
                          onChange={(value) =>
                            handleInputChange("checkboxtwo", value)
                          }
                          isRight={true}
                        />
                        <div className="ml-2">Remember Me</div>
                      </div>
                      <div
                        onClick={() => navigate("forgot-password")}
                        className="admin-forgot-password"
                      >
                        Forgot Password ?
                      </div>
                    </div>
                    <div className="admin-button">
                      <SmartSoftButton
                        label="LOG IN"
                        classList={["admin-login-button"]}
                        onClick={handleSubmit}
                      />
                    </div>
                  </div>
                </div>

                <div className=" admin-login-image-view is-hidden-mobile">
                  <div className="login-image-view">
                    <div className="admin-login-images ">
                      <img
                        className="admin-image-box is-hidden-mobile"
                        src={CROP_ADMIN_LOGIN_IMAGE}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLogin;
