import React, { useState, useEffect } from "react";
import {
  SmartSoftForm,
  SmartSoftButton,
  SmartSoftInput,
  SmartSofFile,
} from "soft_digi";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { post } from "../../../../services/smartApiService";
import { ADMIN_OPERATIONS_SECTOR } from "../../../../api/Admin/OperationsUrl";
import {
  showAlertAutoClose,
  showNotification,
} from "../../../../services/notifyService";
import { getImageContent } from "../../../../services/fileService";
import {
  ALLOW_FLOAT,
  ALLOW_ALPHABET,
} from "../../../../services/PatternSerivce";
import { SmartValid } from "soft_digi";

const SectorForm = ({ loadTableData, dataIn }) => {
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };
  // console.log("dataIn " , dataIn);
  const [formData, setFormData] = useState(dataIn ? dataIn : {});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading, closeModal } = useSiteContext();
  const [roles, setRoles] = useState([]);
  //const [type, setType] = useState("password");

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleClear = () => {
    setFormData(dataIn ? dataIn : {});
    setFormSubmit(false);
  };

  const handleSubmit = () => {
    setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Details Submitting Please Wait....");
    let url = ADMIN_OPERATIONS_SECTOR.INSERT;
    if (formData.ID !== undefined) {
      formData["id"] = formData.ID;
      url = ADMIN_OPERATIONS_SECTOR.UPDATE;
    }

    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        //console.log("response form ", response.data);
        loadTableData();
        setFormData({});
        showAlertAutoClose("Data Saved Successfully", "success");
        closeModal();
        // setUser(response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const validations = {
    sector_name: {},
  };

  const options = [
    { value: "Yes", label: "YES" },
    { value: "NO", label: "NO" },
  ];

  const SectorFormValidations = {
    PickUpDelivery: [SmartValid.required("PickUp & Delivery is Required")],
    OperatingHours: [SmartValid.required("Operating Hours is Required")],
    Sector: [SmartValid.required("Sector is Required")],
    GST: [SmartValid.required("GST is Required")],

    // SmartValid.minLength("Number should be minimum 10 digit", 9),
  };
  const option = [
    { value: "Yes", label: "YES" },
    { value: "No", label: "NO" },
  ];
  const formElements = [
    {
      type: "CHECK_RADIO",
      width: "12",
      name: "pickup_delivery",
      element: {
        classList: ["has-text-info is-size-3"],
        options: options,
        label: "PickUp & Delivery",
        isHorizontal: true,
        validations: SectorFormValidations.PickUpDelivery,
        isMulti: true,
        type: "radio",
      },
    },
  ];
  const formElement = [
    {
      type: "CHECK_RADIO",
      width: "12",
      name: "operating_hours",
      element: {
        classList: ["has-text-info"],
        options: option,
        label: "Operating Hours",
        validations: SectorFormValidations.OperatingHours,
        isHorizontal: true,
        isMulti: true,
        type: "radio",
      },
    },
  ];

  useEffect(() => {}, [dataIn]);

  const sector_form = () => {
    return (
      <div key={"SectorFormcolumnsone"} className="columns is-multiline">
        <div
          className="column is-12 crop-admin-table-title has-text-centered"
          key="1"
        >
          Sector Name
        </div>
        <div
          key={"SectorFormcolumnsone"}
          className="column is-8  is-offset-2 crop-admin-form"
         
        >
          <SmartSoftInput
            label="Sector"
            value={formData?.sector_name || ""}
            inputType="BORDER_LABEL"
            placeHolder=""
            pattern={ALLOW_ALPHABET}
            // elements={sectorelement}
            max={200}
            onChange={(value) => handleInputChange("sector_name", value)}
            errorEnable={formSubmit}
            errorUpdate={(value) => handleErrorChange("euserid", value)}
            validations={SectorFormValidations.Sector}
          />
        </div>
        <div className="column is-8  is-offset-2 crop-admin-form" key="3">
          <SmartSoftInput
            label="GST"
            value={formData?.gst_sector || ""}
            inputType="BORDER_LABEL"
            placeHolder=""
            onChange={(value) => handleInputChange("gst_sector", value)}
            errorEnable={formSubmit}
            pattern={ALLOW_FLOAT}
            max={3}
            errorUpdate={(value) => handleErrorChange("euserid", value)}
            validations={SectorFormValidations.GST}
          />
        </div>

        <div
          key={"SectorFormcolumnone"}
          className="column is-8  is-offset-2 is-flex is-justify-content-center pr-2"
        >
          <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={formElements}
            formSubmit={formSubmit}
            errorEnable={formSubmit}
            errorUpdate={(value) => handleErrorChange("euserid", value)}
            validations={SectorFormValidations.PickUpDelivery}
            // handleErrorChange={handleErrorChange}
          />
        </div>
        <div
          key={"SectorFormcolumntwo"}
          className="column is-8  is-offset-2 is-flex is-justify-content-center pr-2"
        >
          <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={formElement}
            formSubmit={formSubmit}
            errorEnable={formSubmit}
            errorUpdate={(value) => handleErrorChange("euserid", value)}
            validations={SectorFormValidations.OperatingHours}
            // handleErrorChange={handleErrorChange}
          />
        </div>
        {/* <div className="column is-8  is-offset-2 crop-admin-form" key="3">
          <SmartSoftInput
            label="Operating Hours"
            value={formData?.operatinghours_sector || ""}
            inputType="BORDER_LABEL"
            placeHolder=""
            onChange={(value) =>
              handleInputChange("operatinghours_sector", value)
            }
            errorEnable={formSubmit}
            pattern={ALLOW_NUMERIC}
            max={100}
          />
        </div> */}
        <div key={"SectorFormcolumnthree"} className="column is-12">
          <div key={"SectorFormcolumnstwo"} className="columns">
            <div className="column is-3  is-offset-1 crop-admin-form" key="4">
              <figure className="image is-64x64 m-0">
                <img
                  className="is-rounded"
                  src={getImageContent(formData.purchase_image)}
                  alt=""
                />
              </figure>
            </div>
            <div className="column is-8  crop-admin-form" key="48">
              <SmartSofFile
                placeHolder="Upload Earn Image"
                value={formData?.purchase_image}
                onChange={(value) => handleInputChange("purchase_image", value)}
                errorEnable={formSubmit}
                fileNameEnable={false}
              />
            </div>
          </div>
        </div>
        <div key={"SectorFormcolumnfour"} className="column is-12">
          <div key={"SectorFormcolumnsthree"} className="columns">
            <div className="column is-3  is-offset-1 crop-admin-form" key="4">
              <figure className="image is-64x64 m-0">
                <img
                  className="is-rounded"
                  src={getImageContent(formData.redumption_image)}
                  alt=""
                />
              </figure>
            </div>
            <div className="column is-8  crop-admin-form" key="48">
              <SmartSofFile
                placeHolder="Upload Redeem Image"
                value={formData?.purchase_image}
                onChange={(value) =>
                  handleInputChange("redumption_image", value)
                }
                errorEnable={formSubmit}
                fileNameEnable={false}
              />
            </div>
          </div>
        </div>

        <div className="column is-2 has-text-centered is-flex" key="6">
          <SmartSoftButton
            key="save"
            label={dataIn && dataIn.ID !== undefined ? "UPDATE" : "ADD"}
            // leftIcon="fa-save"
            classList={["is-rounded crop-admin-action-button"]}
            onClick={handleSubmit}
          />
          <SmartSoftButton
            key="clear"
            label={dataIn && dataIn.ID !== undefined ? "CLEAR" : "CLEAR"}
            // leftIcon="fa-save"
            classList={["is-rounded is-danger ml-4 crop-admin-action-button"]}
            onClick={handleClear}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        key={"SectorFormcolumnfive"}
        className="column is-12 card p-0 crop-admin-card-form smart-holiday-update-card"
      >
        <div className="smart-crop-admin-holiday-card pl-5">Add New Sector</div>
        <div className="card-content">
          <div className="content">{sector_form()}</div>
        </div>
      </div>
    </>
  );
};

export default SectorForm;
