import React, { useEffect, useState } from "react";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { USER_URLS } from "../../../../api/Admin/AurhUrls";
import { showAlertAutoClose, showNotification } from "../../../../services/notifyService";
import { get, post } from "../../../../services/smartApiService";

const Status = ({ loadTableData, dataIn }) => {
  const [id, setID] = useState(dataIn ? dataIn : {});
  const { setLoading, closeModal } = useSiteContext();
  const [formSubmit, setFormSubmit] = useState(false);

  const handleClick = (event) => {
    // console.log(id);
    // console.log("Click", event.target.id);
    setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose("error", errorMessage);
      setLoading(false);
    };
    setLoading(true, "Details Submitting Please Wait....");
    let url = USER_URLS.UPDATE_STATUS;

    let post_data = {
      id: id,
      active_status: event.target.id,
    };
    const subscription = post(url, post_data, handleError).subscribe(
      (response) => {
        loadTableData();
        showAlertAutoClose("Data Saved Successfully","success");
        closeModal();
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };
  return (
    <>
      <div className="is-flex">
        <div
          key={"handleClickcolumnsone"}
          className="columns has-background-white is-multiline"
        >
          <div key={"handleClickcolumnone"} className="column is-6">
            <div
              className="admin-status-button admin-crop-suspended-status"
              id="SUSPENDED"
              onClick={handleClick}
            >
              <div>Suspended</div>
            </div>
          </div>
          <div key={"handleClickcolumntwo"} className="column is-6">
            <div
              className="admin-status-button admin-crop-deactivated-status"
              id="DEACTIVATED"
              onClick={handleClick}
            >
              <div>Deactivated</div>
            </div>
          </div>
          <div key={"handleClickcolumnthree"} className="column is-6">
            <div
              className="admin-status-button admin-crop-presuspended-status"
              id="PRESUSPENDED"
              onClick={handleClick}
            >
              <div>Presuspended</div>
            </div>
          </div>
          <div key={"handleClickcolumnfour"} className="column is-6">
            <div
              className="admin-status-button admin-crop-active-status"
              id="ACTIVE"
              onClick={handleClick}
            >
              <div> Active</div>
            </div>
          </div>
        </div>
        <div key={"handleClickcolumnfive"} className="column is-2 ml-4 mb-6">
          <div className="crop-admin-modal-close" onClick={closeModal}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export default Status;
