import React from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import OperationsRoute from "./OperationsRoute";
import AdminLogin from "../../pages/Admin/Login/AdminLogin";
import AdminForgot from "../../pages/Admin/Login/AdminForgot";
import AdminLayout from "../../themes/AdminLayout/AdminLayout";
import Dashboard from "../../pages/Admin/Dashboard/Dashboard";
import DatabaseRoute from "./DatabaseRoute";
import AccountManagementRoute from "./AccountManagementRoute";
import NotificationConfig from "../../pages/Admin/Notifications/NotificationConfig";
import AccountSettingsRoute from "./AccountSettingsRoute";
import { CommercialForm, Marcom } from "../../pages/Admin";
import PrivateRoute from "../PrivateRoute";

const AdminRoute = () => {
  const protected_routes = () => {
    return (
      <Routes>
        <Route
          path="/dashboard"
          element={
            <AdminLayout>
              <Dashboard />
            </AdminLayout>
          }
        />
        <Route
          path="/operations/*"
          element={
            <AdminLayout>
              <OperationsRoute />
            </AdminLayout>
          }
        />
        <Route
          path="/account-settings/*"
          element={
            <AdminLayout>
              <AccountSettingsRoute />
            </AdminLayout>
          }
        />
        <Route
          path="/account-management/*"
          element={
            <AdminLayout>
              <AccountManagementRoute />
            </AdminLayout>
          }
        />
        <Route
          path="/database/*"
          element={
            <AdminLayout>
              {" "}
              <DatabaseRoute />
            </AdminLayout>
          }
        />
        <Route
          path="/notification"
          element={
            <AdminLayout>
              <NotificationConfig />
            </AdminLayout>
          }
        />
        <Route
          path="/marcom"
          element={
            <AdminLayout>
              <Marcom />
            </AdminLayout>
          }
        />
        <Route
          path="/commercial"
          element={
            <AdminLayout>
              {" "}
              <CommercialForm />
            </AdminLayout>
          }
        />
        <Route path="/forgot" element={<AdminForgot />} />
      </Routes>
    );
  };

  return (
    <Routes>
      <Route path="" element={<AdminLogin />} />
      <Route path="/forgot-password" element={<AdminForgot />} />
      <Route
        path="/*"
        element={<PrivateRoute>{protected_routes()}</PrivateRoute>}
      />
    </Routes>
  );
};

export default AdminRoute;
/*
<Route path="/dashboard" element={<AdminLayout><Dashboard /></AdminLayout>} />
*/
