import React, { useState } from "react";
import { SmartSoftCheckRadioSwitch, SmartSoftTable } from "soft_digi";
import { useNavigate } from "react-router-dom";
import { useSiteContext } from "../../../contexts/SiteProvider";

const InvoiceDetails = () => {
  const [category, setCategory] = useState("1");
  const [tabData, setTabData] = useState([]);
  const { setLoading, openModal, closeModal } = useSiteContext();
  const navigate = useNavigate();

  const baseNav = "/account-management";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const viewlinebtn = [
    {
      label: " ",
      type: "icon",
      leftIcon: "fa fa-file ",
      classList: ["smart-like-icon"],
      // onClick: (data) => {
      //     console.log('data', data);
      //     navigateLink('rate-product-list')
      // }
    },
  ];
  const downloadline = [
    {
      label: " ",
      type: "icon",
      leftIcon: "fa fa-file",
      classList: ["smart-delete-icon"],
      // onClick: (data) => {
      //     console.log('data', data);
      //     navigateLink('rate-product-list')
      // }
    },
  ];

  const columns = [
    { title: "DATE", index: "dateline" },
    { title: "PRODUCT", index: "productline" },
    { title: "PRICE", index: "priceline" },
    { title: "QUANTITY", index: "quantityline" },
    { title: "TOTAL", index: "totalline" },
    { title: "CROPs", index: "cropsline" },
    { title: "VIEW", index: "viewline", type: "buttons", buttons: viewlinebtn },
    {
      title: "DOWNLOAD",
      index: "downloadline",
      type: "buttons",
      buttons: downloadline,
    },
    { title: "INVOICE NUMBER", index: "invoicenuberline" },
  ];

  const data = [
    {
      dateline: "31/07/2023",
      productline: "P1",
      priceline: "240",
      quantityline: "1",
      totalline: "240",
      cropsline: "540",
      viewline: "",
      downloadline: "",
      invoicenuberline: "F19D00C6-0001",
    },
    {
      dateline: "31/07/2023",
      productline: "P2",
      priceline: "240",
      quantityline: "1",
      totalline: "240",
      cropsline: "540",
      viewline: "",
      downloadline: "",
      invoicenuberline: "F19D00C6-0001",
    },
    {
      dateline: "31/07/2023",
      productline: "P3",
      priceline: "240",
      quantityline: "1",
      totalline: "240",
      cropsline: "540",
      viewline: "",
      downloadline: "",
      invoicenuberline: "F19D00C6-0001",
    },
    {
      dateline: "31/07/2023",
      productline: "P4",
      priceline: "240",
      quantityline: "1",
      totalline: "240",
      cropsline: "540",
      viewline: "",
      downloadline: "",
      invoicenuberline: "F19D00C6-0001",
    },
  ];

  const columnstwo = [
    { title: "DATE", index: "dateline" },
    { title: "PRODUCT", index: "productline" },
    { title: "PRICE", index: "priceline" },
    { title: "QUANTITY", index: "quantityline" },
    { title: "TOTAL", index: "totalline" },
    // { title: "CROPs", index: 'cropsline' },
    { title: "VIEW", index: "viewline", type: "buttons", buttons: viewlinebtn },
    {
      title: "DOWNLOAD",
      index: "downloadline",
      type: "buttons",
      buttons: downloadline,
    },
    // { title: "INVOICE NUMBER", index: 'invoicenuberline' },
  ];

  const MyOfferSwitch = () => {
    const [formData, setFormData] = useState({});
    const [formSubmit, setFormSubmit] = useState(false);
    const [formErrors, setFormErrors] = useState(false);

    const Customer_Order_Details = [
      { value: "1", label: "Customer Order Details" },
    ];
    const Customer_CROP_PROP_Purchase_Details = [
      { value: "2", label: "Customer CROP/PROP Purchase Details" },
    ];

    return (
      <>
        <div className="is-flex">
          <div className="my-offer-month-switch mr-5">
            <SmartSoftCheckRadioSwitch
              options={Customer_Order_Details}
              switchMode={true}
              isRight={true}
              name="switchone"
              value={category}
              onChange={(value) => setCategory("1")}
            />
          </div>
          <div className="my-offer-year-switch">
            <SmartSoftCheckRadioSwitch
              options={Customer_CROP_PROP_Purchase_Details}
              switchMode={true}
              isRight={true}
              name="switch"
              value={category}
              onChange={(value) => setCategory("2")}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="InvoiceDetails">
      <div key={"InvoiceDetailsFirstcolumns"} className="columns">
        <div key={"InvoiceDetailsFirstcolumn"} className="column">
          <div className="card">
            <div
              key={"InvoiceDetailsSecondcolumns"}
              className="columns is-vcentered"
            >
              <div
                key={"InvoiceDetailsSecondcolumn"}
                className="column is-1 crop-admin-table-back"
              >
                <span
                  className="icon"
                  onClick={() => navigateLink("transaction-list")}
                >
                  <i className="fa fa-undo" aria-hidden="true"></i>
                </span>
              </div>

              <div
                key={"InvoiceDetailsThirdcolumn"}
                className="column ml-6 is-8 crop-admin-table-title"
              >
                {MyOfferSwitch()}
              </div>

              <div
                key={"InvoiceDetailsFourthcolumn"}
                className="column is-flex  "
              >
                <div className="crop-admin-form"></div>
                {/* <i className="fa fa-caret-left" aria-hidden="true"></i>{" "}
                                <i className="fa fa-caret-right" aria-hidden="true"></i> */}
              </div>
            </div>
            <SmartSoftTable
              columns={columns}
              data={data}
              tableProps={{ className: "crop-table-layout" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetails;
