import React from "react";
import { useSiteContext } from "../../../../contexts/SiteProvider";

const TierStatus = () => {
  const { setLoading, closeModal } = useSiteContext();
  return (
    <>
      <div className="is-flex">
        <div className="columns has-background-white is-multiline">
          <div className="column is-6">
            <div className="admin-status-button admin-crop-tier-status-blue ">
              <div>Blue</div>
            </div>
          </div>
          <div className="column is-6">
            <div className="admin-status-button admin-crop-tier-status-silver">
              <div>Silver</div>
            </div>
          </div>
          <div className="column is-6">
            <div className="admin-status-button admin-crop-tier-status-gold">
              <div>Gold</div>
            </div>
          </div>
          <div className="column is-6">
            <div className="admin-status-button admin-crop-tier-status-platinum ">
              <div> Platinum</div>
            </div>
          </div>
        </div>
        <div className="column is-2 ml-4">
          <div className="crop-admin-modal-close" onClick={closeModal}>
            <i class="fa fa-times" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export default TierStatus;
