import { useState, useEffect } from "react";
import {
  SmartSoftButton,
  SmartSoftCheckRadioSwitch,
  SmartSoftDate,
  SmartSoftTable,
} from "soft_digi";
import { useNavigate } from "react-router-dom";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import {
  showAlertAutoClose,
  showYesOrNoAlert,
} from "../../../../services/notifyService";
import { post } from "../../../../services/smartApiService";
import { ADMIN_BIDSECTOR_SECTOR } from "../../../../api/Admin/OperationsUrl";
import BidOrderChangeForm from "./BidOrderChangeForm";
import BidAdditionForm from "./BidAdditionForm";

const BidSectorTable = () => {
  const [formData, setFormData] = useState({});
  const [category, setCategory] = useState("1");
  const [formErrors, setFormErrors] = useState(false);
  const [tabData, setTabData] = useState([]);
  const navigate = useNavigate();
  const { setLoading, openModal, closeModal } = useSiteContext();
  const currentDate = new Date();
  
  const loadTableData = () => {
    setLoading(true, "Logging in Please Wait....");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    let data_in = {
      bid_date: formData?.from_date,
      category: category,
    };
    const subscription = post(
      ADMIN_BIDSECTOR_SECTOR.GET_ALL_BID_ADMIN,
      data_in,
      handleError
    ).subscribe((response) => {
      // console.log("response " , response);
      setTabData(response.data);
      // setFormData({from_date:currentDate})
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const updateQualifyStatus = (id) => {
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    const subscription = post(
      ADMIN_BIDSECTOR_SECTOR.UPDATE_QUALIFY,
      { id: id },
      handleError
    ).subscribe((response) => {
      setLoading(false);
      loadTableData();
      showAlertAutoClose("Bid marked as qualified", "success");
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const handle_redirection = (id, selection) => {
    if (selection === "yes") {
      updateQualifyStatus(id);
    }
  };

  const updateQualify = (data) => {
    let id = data.ID !== undefined ? data.ID : 0;
    showYesOrNoAlert(
      "Do you Want to Qualify this Bid ? ",
      (selection) => handle_redirection(id, selection),
      "question"
    );
  };

  useEffect(() => {
    loadTableData();
  }, [formData, category]);

  useEffect(() => {
    setFormData({from_date:currentDate})
  }, []);

  const baseNav = "/operations";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const statusTags = [
    { value: "SUBMITTED", Label: "SUBMITTED", class: "is-warning" },
    { value: "QUALIFIED", Label: "QUALIFIED", class: "is-info" },
    { value: "PAID", Label: "PAID", class: "is-success" },
    { value: "CENCELLED", Label: "CANCELLED", class: "is-success" },
    { value: "PUBLISHED", Label: "PUBLISHED", class: "is-success" },
  ];

  const orderUpdate = (data,type) => {

    let id = data.ID !== undefined ? data.ID : 0;
    data.publish_order = data.publish_order?data.publish_order:1
    data.publish_order = type=='down'?data.publish_order+1:data.publish_order-1
    let form_data = {
      bid_date: formData.from_date,
      id: id,
      order: data.publish_order,
    };
    const handleError = (errorMessage) => {
      showAlertAutoClose("error", errorMessage);
      setLoading(false);
    };
    setLoading(true, "Details Submitting Please Wait....");
    let url = ADMIN_BIDSECTOR_SECTOR.UPDATE_ORDER;
    
    const subscription = post(url, form_data, handleError).subscribe(
      (response) => {
        loadTableData();
        showAlertAutoClose("Order Changed Saved Successfully", "success");
        setLoading(false);
      }
    );
    // let modalObject = {
    //   body: (
    //     <BidOrderChangeForm loadTableData={loadTableData} dataIn={form_data} />
    //   ),
    //   modalClass: "crop-admin-modal smart-modal-30",
    //   bodyClose: false,
    // };
    // openModal(modalObject);
  };

  const bidAddition = () => {
    let form_in_data = {
      bid_date: formData?.from_date,
      category: category,
    };
    let modalObject = {
      body: (
        <BidAdditionForm loadTableData={loadTableData} dataIn={form_in_data} />
      ),
      modalClass: "crop-admin-modal smart-modal-70",
      bodyClose: false,
    };
    openModal(modalObject);
  };

  const display_action_button = (data) => {
    if (data.status_desc === "SUBMITTED") {
      return (
        <SmartSoftButton
          label="Qualify"
          onClick={() => updateQualify(data)}
          classList={["is-small", "is-danger"]}
        />
      );
    } else if (data.status_desc === "PAID") {
      return (
        <>
          <SmartSoftButton
            leftIcon="fa-file"
            label={data?.publish_order || 1}
            classList={["is-small", "is-danger"]}
          />
        </>
      );
    }
  };

  const iconButton = [

    {
      type: "icon",
      leftIcon: "fa fa-arrow-up",
      classList: ["is-clickable"],
      onClick: (data) => {
        orderUpdate(data,"up")
      },
      hideFunction: (data) => {
        if (data.status_desc === "PAID") {
          return parseInt(data?.publish_order) === 1  ? true : false;
        }else{
          return true
        }        
      },
    },
    {
      type: "icon",
      leftIcon: "fa fa-arrow-down",
      classList: ["is-clickable"],
      onClick: (data) => {
        orderUpdate(data,"down")
      },
      hideFunction: (data) => {
        return data.status_desc === "PAID"  ? false : true;      
      },
    },
  ];

  const columns = [
    // { title: "S.No", index: "sno", type: "sno", width: "5" },
    { title: "S.No", index: "publish_order", width: "5" },
    { title: "Business Details", index: "business_name" },
    { title: "Offer Details", index: "offer_title" },
    { title: "Base Price(AUD)", index: "base_bid_price", isSortable: true },
    { title: "Bid Price(AUD)", index: "bid_price", isSortable: true },
    {
      title: "From Date",
      index: "start_date",
      isSortable: true,
      type: "date",
      dateFormat: "DD-MM-YYYY",
    },
    {
      title: "To Date",
      index: "end_date",
      isSortable: true,
      type: "date",
      dateFormat: "DD-MM-YYYY",
    },
    { title: "Status", index: "status_desc", type: "tags", tags: statusTags },
    {
      title: "Update",
      index: "action",
      valueFunction: display_action_button,
    },
    {
      title: "Action",
      type: "buttons",
      buttons: iconButton,
      classList: ["m-0"],
    },
  ];

  const Data = [
    {
      sno: 1,
      busdet: "test",
      offerdet: "testing",
      biddate: "24/02/2024",
      baseprice: 205,
      bidprice: 250,
      status: "Opened",
    },
  ];

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const datefield = () => {
    return (
      <div className="is-flex ">
        <SmartSoftDate
          label="Select Date"
          placeHolder="DD/MM/YY"
          value={formData?.from_date || ""}
          onChange={(value) => handleInputChange("from_date", value)}
          inputType="BORDER_LABEL"
          inputProps={{ isFocussed: true }}
          isHorizontal
        />
      </div>
    );
  };

  const MyOfferSwitch = () => {
    const top_ranks = [{ value: "1", label: "Top Ranks" }];
    const promotion_offer = [{ value: "2", label: "Promotion Offer" }];
    return (
      <>
        <div className="is-flex">
          <div className="my-offer-month-switch mr-5">
            <SmartSoftCheckRadioSwitch
              options={top_ranks}
              switchMode={true}
              isRight={true}
              name="switchone"
              value={category}
              onChange={(value) => setCategory("1")}
            />
          </div>
          <div className="my-offer-year-switch">
            <SmartSoftCheckRadioSwitch
              options={promotion_offer}
              switchMode={true}
              isRight={true}
              name="switchtwo"
              value={category}
              onChange={(value) => setCategory("2")}
            />
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <div className="smart-crop-admin-container">
        <div key={"BidSectorTablecolumnsone"} className="columns">
          <div key={"BidSectorTablecolumnone"} className="column">
            <div className="card">
              <div
                key={"BidSectorTablecolumnstwo"}
                className="columns is-vcentered"
              >
                <div
                  key={"BidSectorTablecolumntwo"}
                  className="column is-1 crop-admin-table-back"
                >
                  <span className="icon" onClick={() => navigateLink("home")}>
                    <i className="fa fa-undo" aria-hidden="true"></i>
                  </span>
                </div>
                <div
                  key={"BidSectorTablecolumnthree"}
                  className="column is-7 crop-admin-table-title is-flex"
                >
                  {MyOfferSwitch()}
                </div>

                <div
                  key={"BidSectorTablecolumnfour"}
                  className="column is-3 has-text-right crop-table-top-pagination"
                >
                  {datefield()}
                </div>
                <div
                  key={"BidSectorTablecolumnfive"}
                  className="column is-1 has-text-right crop-table-top-pagination"
                >
                  {formData.from_date && (
                    <SmartSoftButton
                      label="Add"
                      classList={["is-primary"]}
                      onClick={() => bidAddition()}
                    />
                  )}
                </div>
              </div>
              <div></div>
              <SmartSoftTable
                columns={columns}
                data={tabData}
                tableProps={{ className: "crop-table-layout" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BidSectorTable;
