const StarDisplay = ({ rating }) => {
    const stars = [];
    const maxStars = 5;
    // Fill stars based on the rating
    for (let i = 0; i < maxStars; i++) {
      stars.push(
        <i
          class={"fa fa-star-o " + (i < rating ? "crop-star-active" : "")}
          aria-hidden="true"
        ></i>
      );
    }
    return <div className="is-flex promos-item-star-rating my-2 ">
      <span className="mt-0 mr-1"> {stars}</span>
      <span className="">{rating}</span></div>
  };
  
  export default StarDisplay;
  